import { Link } from "@remix-run/react"
import GeengLogo from "~/assets/icons/geengLogo"
import GeengIcon from "~/assets/icons/geengIcon"

type Props = {
  isInSmallSidebar?: boolean
}

/**
 * The application logo
 */
export default ({ isInSmallSidebar }: Props) => {
  return (
    <Link to="/">
      <div className="flex gap-3 items-center">
        <GeengIcon />
        <GeengLogo
          className={isInSmallSidebar ? "hidden group-hover:block" : ""}
        />
      </div>
    </Link>
  )
}
