export default function Icon(props: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.70711 0.919074C1.48949 0.701454 1.13666 0.701454 0.919038 0.919074C0.701418 1.13669 0.701418 1.48953 0.919038 1.70714L14.293 15.0811C14.5107 15.2988 14.8635 15.2988 15.0811 15.0811C15.2987 14.8635 15.2987 14.5107 15.0811 14.2931L1.70711 0.919074Z"
        fill="currentColor"
      />
      <path
        d="M15.9322 8.41122C15.528 9.62631 14.8525 10.7176 13.9773 11.6131L11.6751 9.31093C11.8212 8.90126 11.9008 8.45998 11.9008 8.00011C11.9008 5.84578 10.1544 4.09936 8.00007 4.09936C7.54021 4.09936 7.09892 4.17894 6.68925 4.32507L4.84899 2.48482C5.82147 2.08866 6.88541 1.87036 8.00046 1.87036C11.6934 1.87036 14.8256 4.26484 15.932 7.58437C16.0214 7.85263 16.0215 8.14289 15.9322 8.41122Z"
        fill="currentColor"
      />
      <path
        d="M10.7863 8.00011C10.7863 8.13397 10.7769 8.26563 10.7586 8.39446L7.60573 5.24155C7.73455 5.2233 7.86621 5.21386 8.00007 5.21386C9.53888 5.21386 10.7863 6.46131 10.7863 8.00011Z"
        fill="currentColor"
      />
      <path
        d="M8.39442 10.7587L5.24151 7.60576C5.22326 7.73459 5.21382 7.86625 5.21382 8.00011C5.21382 9.53891 6.46127 10.7864 8.00007 10.7864C8.13394 10.7864 8.2656 10.7769 8.39442 10.7587Z"
        fill="currentColor"
      />
      <path
        d="M4.09932 8.00011C4.09932 7.54024 4.1789 7.09896 4.32504 6.68929L2.02258 4.38683C1.14728 5.28238 0.471605 6.37378 0.0673676 7.589C-0.0218879 7.85732 -0.0218028 8.14759 0.067611 8.41585C1.17401 11.7354 4.30623 14.1299 7.99916 14.1299C9.11435 14.1299 10.1784 13.9115 11.151 13.5152L9.3109 11.6751C8.90122 11.8213 8.45994 11.9009 8.00007 11.9009C5.84575 11.9009 4.09932 10.1544 4.09932 8.00011Z"
        fill="currentColor"
      />
    </svg>
  )
}
