declare global {
  namespace JSX {
    interface IntrinsicElements {
      "math-field": React.DetailedHTMLProps<
        React.HTMLAttributes<MathfieldElement>,
        MathfieldElement
      >
    }
  }
}

import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node"
import { I18nProvider } from "react-aria"
import { json } from "@remix-run/node"
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import type { MathfieldElement } from "mathlive"
import { SnackbarProvider } from "notistack"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useChangeLanguage } from "remix-i18next/react"
import i18next, { localeCookie } from "~/modules/i18next.server"
import { Toaster } from "./components/Toaster"
import "./tailwind.css"
import { ThemeHead, ThemeProvider } from "./utils/theme-provider"
import { getThemeSession } from "./utils/theme.server"
import { getToast } from "./utils/toast.server"

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  { rel: "preconnect", href: "https://fonts.gstatic.com" },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap",
  },
]

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const themeSession = await getThemeSession(request)
  const { toast, headers: toastHeaders } = await getToast(request)
  const locale = await i18next.getLocale(request)

  const headers = new Headers()
  headers.append("Set-Cookie", await localeCookie.serialize(locale))
  if (toastHeaders) {
    toastHeaders.forEach((value, key) => {
      headers.append(key, value)
    })
  }

  return json(
    {
      theme: themeSession.getTheme(),
      toast,
      locale,
    },
    {
      headers,
    },
  )
}

export let handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "translation",
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>("root")

  return (
    <html lang={data?.locale} className={`${data?.theme}`}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ThemeHead ssrTheme={Boolean(data?.theme)} />
      </head>
      <body className="flex flex-col w-screen min-h-screen">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()
  console.error("error caught in root", error)
  return (
    <div className="flex flex-col p-4">
      {isRouteErrorResponse(error)
        ? `${error.status} ${error.statusText}`
        : error instanceof Error
          ? error.message
          : "Unknown Error"}
    </div>
  )
}

export default function App() {
  const data = useLoaderData<typeof loader>()

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  useChangeLanguage(data?.locale || "en")

  const queryClient = new QueryClient()

  return (
    <ThemeProvider specifiedTheme={data.theme}>
      <SnackbarProvider>
        <DndProvider backend={HTML5Backend}>
          <QueryClientProvider client={queryClient}>
            <I18nProvider locale={data?.locale}>
              <Toaster toast={data?.toast} />
              <Outlet />
            </I18nProvider>
          </QueryClientProvider>
        </DndProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
