import { Form, useSubmit } from "@remix-run/react"
import { useTranslation } from "react-i18next"
import CommentIcon from "~/assets/icons/commentIcon"
import Select from "~/components/tailwindComponents/select"
import SelectRAC, { SelectItem } from "~/components/Select"

export const LANGUAGE_FR = "fr"
export const LANGUAGE_EN = "en"

export const LocaleEnum = Object.freeze({
  fr: "Français (FR)",
  en: "English (EN)",
})

const options = [
  {
    id: LANGUAGE_FR,
    textValue: LocaleEnum.fr,
    key: LANGUAGE_FR,
  },
  {
    id: LANGUAGE_EN,
    textValue: LocaleEnum.en,
    key: LANGUAGE_EN,
  },
]

/**
 * Language selector component used in the app to select the language of interfaces
 * @param props object containing
 * locale: the selected language
 */
export default ({ locale }: { locale: string }) => {
  const submit = useSubmit()
  const { t } = useTranslation()

  return (
    <Form>
      <Select
        className="min-w-[180px]"
        options={options}
        aria-label={t("LOGIN_LANGUAGE_SELECTOR_LABEL")}
        startIcon={<CommentIcon className="mr-2" />}
        variant={"information"}
        compact
        onChange={(locale: string) =>
          submit({ lng: locale }, { method: "get", preventScrollReset: true })
        }
        value={locale}
      />
    </Form>
  )
}
