export default function Icon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_16_2559)">
        <path
          d="M10.8495 0H37.1505C43.1388 0 48 4.86122 48 10.8495V37.1505C48 43.1388 43.1388 48 37.1505 48H10.8495C4.86122 48 0 43.1388 0 37.1505V10.8495C0 4.86122 4.86122 0 10.8495 0Z"
          fill="#16181E"
        />
        <path
          d="M32.1863 24C32.1863 28.5131 28.5146 32.1849 24.0003 32.1849C19.4859 32.1849 15.8154 28.5131 15.8154 24C15.8154 19.4869 19.4871 15.8152 24.0003 15.8152V10.8447C16.7456 10.8447 10.845 16.7466 10.845 24C10.845 31.2534 16.7468 37.1553 24.0003 37.1553C31.2537 37.1553 37.1567 31.2534 37.1567 24H32.1863Z"
          fill="white"
        />
        <path d="M32.1827 19.0295H24.0002V24H32.1827V19.0295Z" fill="#00B2BB" />
      </g>
      <defs>
        <clipPath id="clip0_16_2559">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
