export default function Icon(props: any) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00033 0.666748C3.95024 0.666748 0.666992 3.94999 0.666992 8.00008C0.666992 12.0502 3.95024 15.3334 8.00033 15.3334C12.0504 15.3334 15.3337 12.0502 15.3337 8.00008C15.3337 3.94999 12.0504 0.666748 8.00033 0.666748ZM6.7069 5.90889C6.4866 5.6886 6.12943 5.6886 5.90914 5.90889C5.68884 6.12919 5.68884 6.48636 5.90914 6.70665L7.20256 8.00008L5.90914 9.29351C5.68884 9.51381 5.68884 9.87097 5.90914 10.0913C6.12943 10.3116 6.4866 10.3116 6.7069 10.0913L8.00033 8.79784L9.29375 10.0913C9.51405 10.3116 9.87122 10.3116 10.0915 10.0913C10.3118 9.87097 10.3118 9.51381 10.0915 9.29351L8.79809 8.00008L10.0915 6.70665C10.3118 6.48636 10.3118 6.12919 10.0915 5.90889C9.87122 5.6886 9.51405 5.6886 9.29375 5.90889L8.00033 7.20232L6.7069 5.90889Z"
        fill="currentColor"
      />
    </svg>
  )
}
