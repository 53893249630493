import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react"
import clsx from "clsx"
import { Check, ChevronDown, ChevronUp, ChevronsUpDown } from "lucide-react"
import { tv } from "tailwind-variants"
import ClearIcon from "~/assets/icons/clearIconOutline"
import { Label } from "../Field"

export const listboxOptionsStyles = tv({
  base: "absolute flex flex-col z-10 max-h-64 w-full overflow-x-hidden overflow-y-auto focus:outline-none",
  variants: {
    variant: {
      primary: "text-primary-700 text-sm bg-primary-25",
      secondary: "text-primary-800 text-base bg-white",
      error: "text-red-500 text-base bg-white",
      information: "text-blue-500 text-base bg-white",
    },
    open: {
      true: "",
      false: "",
    },
  },
})

export const listboxOptionStyles = tv({
  base: "flex w-full select-none border-r cursor-pointer font-medium",
  variants: {
    focus: {
      true: "border-l-2 bg-secondary-main-10 border-l-blue-500",
      false: "border-l",
    },
    isLast: {
      true: "border-b rounded-b",
      false: "",
    },
    variant: {
      information: "border-r-blue-100 text-base",
      primary: "border-r-primary-25 text-sm",
      secondary: "border-r-primary-50 text-base",
      error: "border-r-primary-50 text-base",
    },
    compact: { true: "pr-1 py-1", false: "py-3 pr-3" },
  },
  compoundVariants: [
    { focus: true, compact: false, class: "pl-3" },
    { focus: false, compact: false, class: "pl-[13px]" },
    { focus: true, compact: true, class: "pl-1.5" },
    { focus: false, compact: true, class: "pl-[7px]" },
    {
      focus: false,
      variant: "information",
      class: "border-l-blue-100",
    },
    {
      focus: false,
      variant: ["secondary", "error"],
      class: "border-l-primary-50",
    },
    {
      focus: false,
      variant: "primary",
      class: "border-l-primary-25",
    },
    {
      isLast: true,
      variant: "information",
      class: "border-b-blue-100",
    },
    {
      isLast: true,
      variant: ["secondary", "error"],
      class: "border-b-primary-50",
    },
    {
      isLast: true,
      variant: "primary",
      class: "border-b-primary-25",
    },
  ],
})

export const listboxOptionCheckboxStyles = tv({
  base: "flex items-center",
  variants: {},
})

const listboxButtonStyles = tv({
  base: "flex flex-row items-center justify-between text-start ring-1 ring-inset w-full cursor-pointer focus:outline-none focus:ring-2 gap-2 text-base",
  variants: {
    variant: {
      primary:
        "text-primary-700 bg-primary-25 ring-primary-25 focus:ring-secondary-main-100",
      error:
        "ring-red-100 focus:ring-red-500 bg-red-25 text-red-500 font-medium",
      secondary:
        "text-primary-900 bg-white ring-primary-50 focus:ring-secondary-main-100",
      information: "text-blue-500 bg-blue-50 ring-blue-50 focus:ring-blue-500",
    },
    open: {
      true: "rounded-t",
      false: "rounded",
    },
    compact: {
      true: "pl-2 pr-1 py-2",
      false: "pl-3 pr-2 py-3",
    },
  },
})

const placeholderStyle = tv({
  base: "block truncate font-normal",
  variants: {
    variant: {
      primary: "text-primary-700",
      secondary: "text-primary-900",
      error: "text-red-500",
      information: "text-blue-400",
    },
  },
})

const dropdownIconStyles = tv({
  base: "w-4 h-4 group-disabled:text-sidebar-gray-hover",
  variants: {
    variant: {
      primary: "text-primary-500",
      secondary: "text-primary-500",
      error: "text-red-500",
      information: "text-blue-500",
    },
  },
})

const buttonIcon = (
  icon: "up" | "down" | "up-down",
  variant: Props["variant"],
) => {
  switch (icon) {
    case "up-down":
      return (
        <ChevronsUpDown
          className={dropdownIconStyles({ variant })}
          aria-hidden="true"
        />
      )
    case "up":
      return (
        <ChevronUp
          className={dropdownIconStyles({ variant })}
          aria-hidden="true"
        />
      )
    default:
    case "down":
      return (
        <ChevronDown
          className={dropdownIconStyles({ variant })}
          aria-hidden="true"
        />
      )
  }
}

type Props = {
  label?: string
  placeholder?: string
  options: any[]
  optionRenderer?: Function
  valueRenderer?: Function
  variant?: "primary" | "secondary" | "error" | "information"
  compact?: boolean
  icon?: "down" | "up" | "up-down"
  className?: string
  name?: string
  id?: string
  multiple?: boolean
  errorMessage?: string
  isInvalid?: boolean
  defaultValue?: string | (string | undefined | number)[] | number
  value?: string | (string | undefined | number)[] | number
  onChange?:
    | ((value: string | (string | undefined)[]) => void)
    | ((value: string) => void)
  disabled?: boolean
  startIcon?: JSX.Element
}

/**
 * TailwindUI Select component implementation with the application styles (based on https://tailwindui.com/components/application-ui/forms/select-menus)
 * @param object containing
 * label: the label to associate with the select input
 * options: the available options of the menu
 * optionRenderer: eventual option renderer (function that returns a JSX Element)
 * valueRenderer: eventual value renderer (function that returns a JSX Element)
 * placeholder: text that should be displayed if the select value is undefined
 * icon: the icon to display at the right of the component (possible values: "down" | "up" | "up-down")
 * className: eventual className to add to the span component
 * variant: tailwind variant to style the component (possible values: "primary" | "secondary" | "error" | "information")
 * compact: tailwind variant to style the component (possible values: true | false)
 * errorMessage: error message to display at the bottom of the component
 * isInvalid: boolean value indicating if the select component is invalid
 * startIcon: potential icon to display at the start of the select
 */
const Select = ({
  label,
  options,
  optionRenderer,
  valueRenderer,
  placeholder,
  icon = "down",
  className,
  variant = "primary",
  compact = false,
  errorMessage,
  isInvalid,
  name,
  id,
  startIcon,
  ...props
}: Props) => {
  return (
    <Listbox {...props}>
      {({ open }) => (
        <div className="flex flex-col gap-2">
          {label && <Label variant={variant}>{label}</Label>}
          <div className="relative rounded">
            <ListboxButton
              className={clsx(
                listboxButtonStyles({ variant, open, compact }),
                className,
              )}
            >
              {({ value }) => (
                <>
                  {startIcon ?? null}
                  <input
                    name={name}
                    id={id}
                    value={value}
                    readOnly
                    className="hidden"
                  />
                  {(props.multiple && value?.length > 0) ||
                  (!props.multiple && value) ? (
                    valueRenderer ? (
                      <span className="flex gap-1 items-center truncate h-[17.5px]">
                        {options.filter(({ id }) =>
                          props.multiple ? value.includes(id) : id === value,
                        ).length > 0
                          ? options
                              .filter(({ id }) =>
                                props.multiple
                                  ? value.includes(id)
                                  : id === value,
                              )
                              .map((option) => valueRenderer(option))
                          : placeholder}
                      </span>
                    ) : (
                      <span className="block truncate">
                        {options
                          .filter(({ id }) =>
                            props.multiple ? value.includes(id) : id === value,
                          )
                          .map(({ textValue }) => textValue)
                          .join(", ")}
                      </span>
                    )
                  ) : (
                    <span className={placeholderStyle({ variant })}>
                      {placeholder || ""}
                    </span>
                  )}
                  <div className="flex items-center gap-1">
                    {isInvalid && (
                      <ClearIcon
                        className="pointer-events-none h-5 w-5 text-red-500 mr-1"
                        aria-hidden="true"
                      />
                    )}
                    <span className="pointer-events-none flex items-center">
                      {buttonIcon(icon, variant)}
                    </span>
                  </div>
                </>
              )}
            </ListboxButton>
            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions
                className={listboxOptionsStyles({ variant, open })}
              >
                {options.map((option, idx) => (
                  <ListboxOption
                    key={option.id}
                    className={({ focus }) =>
                      listboxOptionStyles({
                        focus,
                        isLast: idx === options.length - 1,
                        variant,
                        compact,
                      })
                    }
                    value={option.id}
                  >
                    {({ selected }) => (
                      <div
                        className={clsx(
                          "flex items-center justify-between gap-2 w-full",
                          selected ? "font-semibold" : "font-medium",
                        )}
                      >
                        {optionRenderer ? (
                          optionRenderer(option)
                        ) : (
                          <span>{option.textValue}</span>
                        )}
                        {selected ? (
                          <span className={listboxOptionCheckboxStyles()}>
                            <Check className="h-3 w-3" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
          {errorMessage && (
            <span className="text-xs text-red-500">{errorMessage}</span>
          )}
        </div>
      )}
    </Listbox>
  )
}

export default Select
