/**
 * This file contains all the french translations for the keys used in the application
 */
export default {
  /* OTHERS */
  ADD: "Ajouter",
  ADD_NEW_OPTION: "Ajouter '{{inputValue}}'",
  AND: "Et",
  APPLICABLE: "Applicable",
  BACK_TO_HOME: "Retour à l'accueil",
  BACK_TO_DPCR_HOME: "Retour à l'accueil DPCR",
  CANCEL: "Annuler",
  CLOSE: "Fermer",
  COPY: "Copier",
  DEMO: "Démo",
  DETAILS: "Détails",
  EDIT: "Éditer",
  ERROR_404: "Cette page n'existe pas.",
  LIST_MORE_ITEMS_zero: "{{count}} autre",
  LIST_MORE_ITEMS_one: "{{count}} autre",
  LIST_MORE_ITEMS_other: "{{count}} autres",
  NAVIGATE_BACK: "Retour",
  NEXT: "Suivant",
  NEW: "Nouveau",
  OPEN: "Ouvrir",
  OR: "ou",
  PASTE: "Coller",
  POURCENTAGE_RESULT: "{{value}}%",
  SAVE: "Sauvegarder",
  STEP_INDEX: "Étape {{index}}",
  /* ADMINISTRATION_HOME */
  ADMINISTRATION_HOME_TITLE_DESCRIPTION: "Administration",
  ADMINISTRATION_HOME_TITLE: "Administration",
  ADMINISTRATION_HOME_CARD_USERS_TITLE: "Utilisateurs",
  ADMINISTRATION_HOME_CARD_USERS_SUBTITLE: "Gestion des utilisateurs",
  /* ADMINISTRATION_USERS */
  ADMINISTRATION_USERS_TITLE_DESCRIPTION:
    "Administration - Gestion des utilisateurs",
  ADMINISTRATION_USERS_TITLE: "Administration - Gestion des utilisateurs",
  ADMINISTRATION_USERS_ADD_NEW_USER_BUTTON: "Ajouter utilisateur",
  ADMINISTRATION_USERS_TABLE_HEADER_TITLE: "Tous les utilisateurs",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_zero: "{{count}} Utilisateur",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_one: "{{count}} Utilisateur",
  ADMINISTRATION_USERS_TABLE_HEADER_BADGE_other: "{{count}} Utilisateurs",
  ADMINISTRATION_USERS_TABLE_HEADER_FILTER_BUTTON: "Filtrer",
  ADMINISTRATION_USERS_TABLE_HEADER_FILTER_GROUP_LABEL: "Filtrer par groupe",
  ADMINISTRATION_USERS_TABLE_HEADER_FILTER_GROUP_PLACEHOLDER:
    "Sélectionner un groupe",
  ADMINISTRATION_USERS_TABLE_COLUMN_NAME: "Nom",
  ADMINISTRATION_USERS_TABLE_COLUMN_MAIL: "Adresse mail",
  ADMINISTRATION_USERS_TABLE_COLUMN_ROLES: "Roles",
  ADMINISTRATION_USERS_TABLE_COLUMN_CREATION_DATE: "Date de création",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIVITY: "Actif",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIVITY_ACTIVE: "Actif",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIVITY_INACTIVE: "Inactif",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_BUTTON_LABEL:
    "Actions pour l'utilisateur",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_ACTIVATE:
    "Activer l'utilisateur",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_DEACTIVATE:
    "Désactiver l'utilisateur",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_ACTIVATE_SUCCESS_TOAST_MESSAGE:
    "L'utilisateur a été mis à jour avec succès",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_ACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur est survenue pendant la mise à jour de l'utilisateur. Veuillez réessayez.",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_MENU_MANAGE_GROUPS:
    "Gestion des rôles",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_TITLE:
    "Gestion des rôles de l'utilisateur",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_SUBTITLE:
    "Choisissez les rôles pour cet utilisateur",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_DELETE_GROUP_SUCCESS_TOAST_MESSAGE:
    "Rôle de l'utilisateur supprimé avec succès",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_DELETE_GROUP_ERROR_TOAST_MESSAGE:
    "Une erreur est survenue pendant la suppression du rôle de l'utilisateur. Veuillez réessayez.",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_ADD_GROUP_SUCCESS_TOAST_MESSAGE:
    "Rôle de l'utilisateur ajouté avec succès",
  ADMINISTRATION_USERS_TABLE_COLUMN_ACTIONS_DIALOG_MANAGE_GROUPS_ADD_GROUP_ERROR_TOAST_MESSAGE:
    "Une erreur est survenue pendant l'ajout du rôle de l'utilisateur. Veuillez réessayez.",
  /* ADMINISTRATION USERS CREATION */
  ADMINISTRATION_USERS_CREATION_TITLE_DESCRIPTION:
    "Création d'un nouvel utilisateur",
  ADMINISTRATION_USERS_CREATION_TITLE: "Création d'un nouvel utilisateur",
  ADMINISTRATION_USERS_CREATION_SUBTITLE:
    "Définissez-ici les informations concernant l'utilisateur : son identité, adresse mail et les rôles que vous souhaitez lui accorder.",
  ADMINISTRATION_USERS_CREATION_FORM_IDENTITY_TITLE: "Identité",
  ADMINISTRATION_USERS_CREATION_FORM_IDENTITY_SUBTITLE:
    "Informations sur l'identité de l'utilisateur",
  ADMINISTRATION_USERS_CREATION_FORM_FIRSTNAME_LABEL: "Prénom",
  ADMINISTRATION_USERS_CREATION_FORM_LASTNAME_LABEL: "Nom",
  ADMINISTRATION_USERS_CREATION_FORM_USERNAME_LABEL: "Nom d'utilisateur",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_TITLE: "Adresse mail",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_SUBTITLE:
    "Informations concernant l'adresse mail de l'utilisateur",
  ADMINISTRATION_USERS_CREATION_FORM_MAIL_LABEL: "Adresse mail",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_TITLE: "Rôles",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_SUBTITLE:
    "Choisissez les rôles accordés à l'utilisateur",
  ADMINISTRATION_USERS_CREATION_FORM_GROUPS_LABEL: "Rôles",
  ADMINISTRATION_USERS_CREATION_CANCEL_BUTTON_LABEL: "Annuler",
  ADMINISTRATION_USERS_CREATION_FORM_SUBMIT_BUTTON_LABEL: "Créer l'utilisateur",
  ADMINISTRATION_USERS_CREATION_SUCCESS_TOAST_MESSAGE:
    "Utilisateur créé avec succès",
  /* ADMINISTRATION USERS ROLES */
  ROLE_ADMINISTRATOR: "Administrateur",
  ROLE_TEST_MANAGER: "Test manager",
  ROLE_USER: "Utilisateur",
  /* ANALYSES QUALITY RULES */
  ANALYSES_QUALITY_RULES_OUTPUT_PASS: "Pass",
  ANALYSES_QUALITY_RULES_OUTPUT_PASS_LIMIT: "Pass limit",
  ANALYSES_QUALITY_RULES_OUTPUT_LOW: "Low",
  ANALYSES_QUALITY_RULES_OUTPUT_STRONG: "Strong",
  /* ANALYSIS CREATION STEPPER */
  ANALYSES_CREATION_STEPPER_STEP1_TITLE: "Réglages",
  ANALYSES_CREATION_STEPPER_STEP2_TITLE: "Matrices",
  ANALYSES_CREATION_STEPPER_STEP3_TITLE: "Règles de qualité",
  ANALYSES_CREATION_STEPPER_STEP4_TITLE: "Calcul quantité ADN",
  ANALYSES_CREATION_STEPPER_STEP5_TITLE: "LoD et LoB",
  ANALYSES_CREATION_STEPPER_STEP6_TITLE: "Fréquence Allélique",
  ANALYSES_CREATION_STEPPER_STEP7_TITLE: "Sensitivité",
  ANALYSES_CREATION_STEPPER_STEP8_TITLE: "Incertitude",
  ANALYSES_CREATION_STEPPER_STEP9_TITLE: "Validation des témoins",
  ANALYSES_CREATION_STEPPER_STEP10_TITLE: "Règles de décision",
  ANALYSES_CREATION_STEPPER_SUBTITLE_TODO: "À compléter",
  ANALYSES_CREATION_STEPPER_SUBTITLE_CURRENT: "En cours",
  ANALYSES_CREATION_STEPPER_SUBTITLE_DONE: "Validé",
  /* ANALYSIS CREATION */
  ANALYSES_CREATION_NAME_LABEL: "Nom de l'analyse",
  ANALYSES_CREATION_TARGETS_COUNT_LABEL: "Nombre de cibles",
  ANALYSES_CREATION_CHIP_LABEL: "Puce",
  ANALYSES_CREATION_MATRICES_LABEL: "Matrices",
  ANALYSES_CREATION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite. Veuillez réessayer",
  ANALYSES_CREATION_SUCCESS_TOAST_MESSAGE:
    "Étape {{step}} de la création d'une analyse effectuée avec succès",
  ANALYSES_CREATION_FORM_REPLICATE_LABEL: "Répétition x{{replicate}}",
  ANALYSES_CREATION_FORM_ADD_BUTTON: "Ajouter condition",
  /* ANALYSIS CREATION STEP 1 */
  ANALYSES_CREATION_FORM_STEP1_TITLE: "Création de l'analyse",
  ANALYSES_CREATION_FORM_STEP1_SUBTITLE:
    "Définissez-ici les informations principales de l'analyse : son nom, le matériel utilisé (puce, kit et logiciel), si l'analyse est en mode développement ou directement production, ainsi que la présence ou non de témoins",
  ANALYSES_CREATION_FORM_STEP1_POSITIVE_CONTROL_LABEL:
    "Témoins positifs applicables",
  ANALYSES_CREATION_FORM_STEP1_CONTROL_PREFIX_PLACEHOLDER:
    "Préfixe nom échantillon",
  ANALYSES_CREATION_FORM_STEP1_CONTROL_PREFIX_TOOLTIP:
    "Afin d'indiquer qu'un échantillon est un {{control}} dans les fichiers de résultats, vous avez 2 possibilités: soit indiquer dans ce champ le préfixe du nom d'échantillon associé à ce type de contrôle (par exemple: '{{exampleValue}}'), soit remplir la colonne SampleType de la samplesheet par '{{samplesheetValue}}' pour cet échantillon.",
  ANALYSES_CREATION_FORM_STEP1_NEGATIVE_CONTROL_LABEL:
    "Témoins négatifs applicables",
  ANALYSES_CREATION_FORM_STEP1_BLANK_CONTROL_LABEL:
    "Témoins blancs applicables",
  ANALYSES_CREATION_FORM_STEP1_MODE_LABEL: "Analyse en production",
  ANALYSES_CREATION_FORM_STEP1_MODE_DESCRIPTION:
    "Par défaut l’analyse est en développement. Ce statut déterminera la possibilité d’éditer certains champs de l’analyse après sa création",
  ANALYSES_CREATION_FORM_STEP1_NAME_DESCRIPTION:
    "Choisissez la nomenclature que vous souhaitez",
  ANALYSES_CREATION_FORM_STEP1_SOFTWARE_LABEL: "Logiciel",
  ANALYSES_CREATION_FORM_STEP1_SOFTWARE_DESCRIPTION:
    "Définit le format des fichiers de résultats",
  ANALYSES_CREATION_FORM_STEP1_SOFTWARE_PLACEHOLDER: "Sélectionner un logiciel",
  ANALYSES_CREATION_FORM_STEP1_SOFTWARE_NO_OPTION: "Aucun logiciel trouvé",
  ANALYSES_CREATION_FORM_STEP1_VERSION_LABEL: "Numéro de version",
  ANALYSES_CREATION_FORM_STEP1_KIT_LABEL: "Kit",
  ANALYSES_CREATION_FORM_STEP1_KIT_DESCRIPTION:
    "Définit les cibles de l'analyse",
  ANALYSES_CREATION_FORM_STEP1_KIT_PLACEHOLDER: "Sélectionner un kit",
  ANALYSES_CREATION_FORM_STEP1_KIT_NO_OPTION: "Aucun kit trouvé",
  ANALYSES_CREATION_FORM_STEP1_CHIP_PLACEHOLDER: "Sélectionner une puce",
  ANALYSES_CREATION_FORM_STEP1_CHIP_NO_OPTION: "Aucune puce trouvée",
  ANALYSES_CREATION_FORM_STEP1_SUBMIT: "Créer l'analyse",
  /* ANALYSIS CREATION STEP 2 */
  ANALYSES_CREATION_FORM_STEP2_TITLE: "Définition des matrices de l'analyse",
  ANALYSES_CREATION_FORM_STEP2_SUBTITLE:
    "Définissez-ici les matrices (fDNA, cfDNA, etc.) associées à l'analyse.",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_LABEL: "Liste des matrices",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_SUBTITLE:
    "Indiquez le nombre de répétitions par matrice. Ceci aura un impact sur les étapes suivantes.",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_ADD_BUTTON: "Ajouter matrice",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_NAME_LABEL: "Nom de la matrice",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_HEADER_MATRICES: "Matrices",
  ANALYSES_CREATION_FORM_STEP2_MATRICES_HEADER_REPETITIONS: "Répétitions",
  ANALYSES_CREATION_FORM_STEP2_REPETITIONS_PLACEHOLDER:
    "Sélectionner une ou plusieurs répétitions",
  /* ANALYSIS CREATION STEP 3 OPERATORS */
  GREATER: "Supérieur à",
  LESS_EQUAL: "Inférieur ou égal à",
  /* ANALYSIS CREATION STEP 3 */
  ANALYSES_CREATION_FORM_STEP3_TITLE:
    "Définition des règles de contrôle qualité de l'analyse",
  ANALYSES_CREATION_FORM_STEP3_SUBTITLE:
    "Définissez-ici les règles de contrôle qualité de l'analyse pour chaque matrice (fDNA, cfDNA, etc.) en précisant les critères et conditions à respecter pour valider l'analyse.",
  ANALYSES_CREATION_FORM_STEP3_TABS_LABEL:
    "Sélectionnez l'une des matrices de l'analyse",
  ANALYSES_CREATION_FORM_STEP3_QC_RULE_MATHS_FORMULA_LABEL:
    "Formule de la règle de contrôle qualité",
  ANALYSES_CREATION_FORM_STEP3_QC_RULE_MATHS_FORMULA_MODAL_TITLE:
    "Règle de qualité - {{matrix}}",
  ANALYSES_CREATION_FORM_STEP3_QC_RULE_CONDITIONS_LABEL: "Règles de qualité",
  ANALYSES_CREATION_FORM_STEP3_QC_RULE_CUTOFF_LABEL: "Valeur seuil",
  ANALYSES_CREATION_FORM_STEP3_QC_RULE_OUTPUT_LABEL: "Valeur de sortie",
  /* ANALYSIS CREATION STEP 4 */
  ANALYSES_CREATION_FORM_STEP4_TITLE:
    "Définition du calcul de la quantité d'ADN de l'analyse",
  ANALYSES_CREATION_FORM_STEP4_SUBTITLE:
    "Définissez-ici la formule de calcul de la quantité d'ADN de l'analyse en précisant les critères et conditions à respecter pour valider l'analyse.",
  ANALYSES_CREATION_FORM_STEP4_QC_RULE_MATHS_FORMULA_LABEL:
    "Formule de calcul de la quantité d'ADN",
  ANALYSES_CREATION_FORM_STEP4_QC_RULE_MATHS_FORMULA_MODAL_TITLE:
    "Règle de calcul de la quantité d'ADN",
  ANALYSES_CREATION_FORM_STEP4_QC_RULE_CONDITIONS_LABEL: "Règles de qualité",
  ANALYSES_CREATION_FORM_STEP4_QC_RULE_CUTOFF_LABEL: "Valeur seuil",
  ANALYSES_CREATION_FORM_STEP4_QC_RULE_OUTPUT_LABEL: "Valeur de sortie",
  /* ANALYSIS CREATION STEP 5 */
  ANALYSES_CREATION_FORM_STEP5_TITLE:
    "Définition des Limit of Blank et Limit of Decision",
  ANALYSES_CREATION_FORM_STEP5_SUBTITLE:
    "Définissez-ici les Limit of Blank (LoB) et Limit of Decision (LoD) de votre analyse pour chaque matrice, répétition et cible du kit associé à l'analyse.",
  ANALYSES_CREATION_FORM_STEP5_TABS_LABEL:
    "Sélectionnez l'une des matrices de l'analyse",
  ANALYSES_CREATION_FORM_STEP5_LOB_BADGE_LABEL:
    "LoB {{validated}}/{{expected}}",
  ANALYSES_CREATION_FORM_STEP5_LOD_BADGE_LABEL:
    "LoD {{validated}}/{{expected}}",
  ANALYSES_CREATION_FORM_STEP5_LOB_LABEL: "Définition LoB",
  ANALYSES_CREATION_FORM_STEP5_LOD_LABEL: "Définition LoD",
  ANALYSES_CREATION_FORM_STEP5_UNDEFINED_PLACEHOLDER: "Non défini",
  ANALYSES_CREATION_FORM_STEP5_LOB_LOD_UNIT: "en cp/μL",
  /* ANALYSIS CREATION STEP 6 */
  ANALYSES_CREATION_FORM_STEP6_TITLE: "Calcul des fréquences alléliques",
  ANALYSES_CREATION_FORM_STEP6_SUBTITLE:
    "Définissez-ici les formules de calcul de la fréquence allélique (VAF) pour chaque cible du kit associé à l'analyse. Vous pouvez appliquer les mêmes formules à d'autres cibles si nécessaire. Pour ce faire, utilisez les boutons copier et coller situés à droite des champs de saisie de formules. En collant une formule de la cible A vers la cible B (en utilisant les boutons copier et coller), les variables de la cible A seront automatiquement remplacées par les variables de la cible B.",
  ANALYSES_CREATION_FORM_STEP6_MATHS_FORMULA_MODAL_TITLE:
    "Définition de la VAF - Cible n°{{targetIndex}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP6_MATHS_FORMULA_INAPPLICABLE_LABEL:
    "VAF non applicable",
  /* ANALYSIS CREATION STEP 7 */
  ANALYSES_CREATION_FORM_STEP7_TITLE: "Calcul de la sensitivité",
  ANALYSES_CREATION_FORM_STEP7_SUBTITLE:
    "Ici, vous pouvez définir la sensitivité de votre analyse dPCR. Ce paramètre est essentiel pour évaluer la capacité de la méthode à détecter de faibles quantités de cibles dans un échantillon. En ajustant la sensitivité, vous pourrez déterminer le seuil minimal de détection requis, garantissant ainsi des résultats fiables, même lorsque la concentration de cible est faible.  Vous pouvez appliquer les mêmes formules à d'autres cibles si nécessaire. Pour ce faire, utilisez les boutons copier et coller situés à droite des champs de saisie de formules. En collant une formule de la cible A vers la cible B (en utilisant les boutons copier et coller), les variables de la cible A seront automatiquement remplacées par les variables de la cible B.",
  ANALYSES_CREATION_FORM_STEP7_MATHS_FORMULA_MODAL_TITLE:
    "Définition de la sensitivité - Cible n°{{targetIndex}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP7_MATHS_FORMULA_INAPPLICABLE_LABEL:
    "Sensitivité non applicable",
  /* ANALYSIS CREATION STEP 8 */
  ANALYSES_CREATION_FORM_STEP8_TITLE: "Calcul de l'incertitude",
  ANALYSES_CREATION_FORM_STEP8_SUBTITLE:
    "Ici, vous pouvez définir l'incertitude de votre analyse en dPCR. Vous pouvez appliquer les mêmes formules à d'autres cibles si nécessaire. Pour ce faire, utilisez les boutons copier et coller situés à droite des champs de saisie de formules. En collant une formule de la cible A vers la cible B (en utilisant les boutons copier et coller), les variables de la cible A seront automatiquement remplacées par les variables de la cible B.",
  ANALYSES_CREATION_FORM_STEP8_MATHS_FORMULA_MODAL_TITLE:
    "Définition de l'incertitude - Cible n°{{targetIndex}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP8_MATHS_FORMULA_INAPPLICABLE_LABEL:
    "Incertitude non applicable",
  /* ANALYSIS CREATION STEP 9 */
  ANALYSES_CREATION_FORM_STEP9_TITLE: "Contrôle des témoins",
  ANALYSES_CREATION_FORM_STEP9_SUBTITLE:
    "Définissez-ici les règles de contrôle associées aux témoins de l'analyse. Pour chaque type de témoin (blanc, positif ou négtif), et chaque cible vous pouvez définir votre propre règle de contrôle. Vous pouvez appliquer les mêmes formules à d'autres cibles si nécessaire. Pour ce faire, utilisez les boutons copier et coller situés à droite des champs de saisie de formules. En collant une formule de la cible A vers la cible B (en utilisant les boutons copier et coller), les variables de la cible A seront automatiquement remplacées par les variables de la cible B.",
  ANALYSES_CREATION_FORM_STEP9_TABS_LABEL:
    "Sélectionnez un type de témoins pour en définir les contrôles pour chaque cible",
  ANALYSES_CREATION_FORM_STEP9_TAB_TITLE: "Contrôle {{control}}",
  ANALYSES_CREATION_FORM_STEP9_MATHS_FORMULA_MODAL_TITLE:
    "{{control}} - Cible n°{{targetIndex}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP9_MATHS_FORMULA_INAPPLICABLE_LABEL:
    "{{control}} non applicable",
  /* ANALYSIS CREATION STEP 10 */
  ANALYSES_CREATION_FORM_STEP10_TITLE: "Règles de décision",
  ANALYSES_CREATION_FORM_STEP10_SUBTITLE:
    "Définissez-ici les règles de décision associées à l'analyse. Vous pouvez d'abord définir les labels d'interprétation pour chaque cible puis pour chaque cible, matrice et répétition, vous pouvez définir votre propre règle de décision et combiner les formules mathématiques si besoin. Vous pouvez également appliquer les mêmes formules à d'autres cibles si nécessaire. Pour ce faire, utilisez les boutons copier et coller situés à droite des champs de saisie de formules. En collant une formule de la cible A vers la cible B (en utilisant les boutons copier et coller), les variables de la cible A seront automatiquement remplacées par les variables de la cible B.",
  ANALYSES_CREATION_FORM_STEP10_TABS_LABEL:
    "Sélectionnez une matrice ou l'onglet des interprétations",
  ANALYSES_CREATION_FORM_STEP10_TAB_INTERPRETATIONS_TITLE: "Interprétations",
  ANALYSES_CREATION_FORM_STEP10_MATHS_FORMULA_MODAL_TITLE:
    "Règle de décision - Cible n°{{targetIndex}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP10_INTERPRETATION_TAB_TABLE_TARGET_COLUMN_TITLE:
    "Cible",
  ANALYSES_CREATION_FORM_STEP10_INTERPRETATION_TAB_TABLE_POSITIVE_COLUMN_TITLE:
    "Positif",
  ANALYSES_CREATION_FORM_STEP10_INTERPRETATION_TAB_TABLE_NEGATIVE_COLUMN_TITLE:
    "Négatif",
  ANALYSES_CREATION_FORM_STEP10_INTERPRETATION_TAB_TABLE_NON_CONTRIBUTIVE_COLUMN_TITLE:
    "Non Contributif",
  ANALYSES_CREATION_FORM_STEP10_INTERPRETATION_TAB_TABLE_APPLICABLE_COLUMN_TITLE:
    "Applicable",
  ANALYSES_CREATION_FORM_STEP10_TARGETS_BADGE_LABEL:
    "{{validated}}/{{expected}}",
  ANALYSES_CREATION_FORM_STEP10_NOT_APPLICABLE_BADGE_LABEL_zero:
    "{{count}} non applicable",
  ANALYSES_CREATION_FORM_STEP10_NOT_APPLICABLE_BADGE_LABEL_one:
    "{{count}} non applicable",
  ANALYSES_CREATION_FORM_STEP10_NOT_APPLICABLE_BADGE_LABEL_other:
    "{{count}} non applicables",
  ANALYSES_CREATION_FORM_STEP10_MATHS_FORMULA_INAPPLICABLE_LABEL:
    "Règle de décision non applicable",
  ANALYSES_CREATION_FORM_STEP10_QC_RULE_MATHS_FORMULA_MODAL_TITLE:
    "Règle de décision - {{matrix}} - Rep. {{repetition}} - {{target}}",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_TITLE:
    "Dans cet onglet vous aurez la possibilité de définir vous-même l'association de règles permettant de prédéfinir un résultat d'analyse d'échantillon comme non contributif. Cette fonctionnalité sera disponible prochainement.",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_EXPLANATION:
    "Pour l'heure, voici les règles associées à la définition d'un résultat comme non contributif.",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_QC_CHIP: "QC Puce",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_QC_CHIP_TOOLTIP:
    "Règle de contrôle associée à la puce utilisée dans cette analyse",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_QC_SAMPLE:
    "QC Échantillon",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_QC_SAMPLE_TOOLTIP:
    "Règle de contrôle qualité définie lors de l'étape 3 de la création de cette analyse",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_DNA_AMOUNT:
    "Quantité d'ADN",
  ANALYSES_CREATION_FORM_STEP10_NON_CONTRIBUTIVE_TAB_DNA_AMOUNT_TOOLTIP:
    "Règle de contrôle qualité liée à la quantité d'ADN et définie lors de l'étape 4 de la création de cette analyse",
  /* ANALYSES CREATION SUMMARY MODAL */
  ANALYSES_CREATION_SUMMARY_MODAL_TITLE: "Résumé de votre création d'analyse",
  ANALYSES_CREATION_SUMMARY_MODAL_INFORMATIONS: "Informations",
  ANALYSES_CREATION_SUMMARY_MODAL_NAME: "Nom",
  ANALYSES_CREATION_SUMMARY_MODAL_VERSION: "Version",
  ANALYSES_CREATION_SUMMARY_MODAL_SOFTWARE: "Logiciel",
  ANALYSES_CREATION_SUMMARY_MODAL_KIT: "Kit",
  ANALYSES_CREATION_SUMMARY_MODAL_CHIP: "Puce",
  ANALYSES_CREATION_SUMMARY_MODAL_TARGETS: "Nombre de cibles",
  ANALYSES_CREATION_SUMMARY_MODAL_TARGETS_VALUE_zero: "{{count}} cible",
  ANALYSES_CREATION_SUMMARY_MODAL_TARGETS_VALUE_one: "{{count}} cible",
  ANALYSES_CREATION_SUMMARY_MODAL_TARGETS_VALUE_other: "{{count}} cibles",
  ANALYSES_CREATION_SUMMARY_MODAL_MATRICES: "Matrices",
  ANALYSES_CREATION_SUMMARY_MODAL_MODE_LABEL: "Analyse en production",
  ANALYSES_CREATION_SUMMARY_MODAL_MODE_DESCRIPTION:
    "Par défaut l’analyse est en développement. Ce statut déterminera la possibilité d’éditer certains champs de l’analyse après sa création",
  /* ANALYSES UPDATE FETCHER */
  ANALYSES_UPDATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la mise à jour de l'analyse. Veuillez réessayer",
  ANALYSES_UPDATE_SUCCESS_TOAST_MESSAGE:
    "La mise à jour de l'analyse s'effectuée avec succès",
  /* ANALYSES DETAILS */
  ANALYSES_DETAILS_ANALYSIS_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Analyse non trouvée. Retour à la liste des analyses",
  ANALYSES_DETAILS_ANALYSIS_CANNOT_BE_UPDATED_ERROR_TOAST_MESSAGE:
    "Cette analyse ne peut être modifiée. Retour à la liste des analyses",
  ANALYSES_DETAILS_TITLE: "Informations de l'analyse '{{analysisName}}'",
  ANALYSES_DETAILS_DESCRIPTION: "Informations de l'analyse '{{analysisName}}'",
  ANALYSES_DETAILS_INFORMATIONS_TITLE: "Informations",
  ANALYSES_DETAILS_INFORMATIONS_SUBTITLE:
    "Informations générales sur l'analyse",
  ANALYSES_DETAILS_NAME_LABEL: "Nom",
  ANALYSES_DETAILS_KIT_LABEL: "Kit",
  ANALYSES_DETAILS_CHIP_LABEL: "Puce",
  ANALYSES_DETAILS_CREATION_LABEL: "Création",
  ANALYSES_DETAILS_CREATION_VALUE: "Le {{- date}}, par {{user}}",
  ANALYSES_DETAILS_CREATION_DATE_VALUE: "Le {{- date}}",
  ANALYSES_DETAILS_LAST_MODIFICATION_LABEL: "Dernière modification",
  ANALYSES_DETAILS_LAST_MODIFICATION_VALUE: "Le {{- date}}, par {{user}}",
  ANALYSES_DETAILS_LAST_MODIFICATION_DATE_VALUE: "Le {{- date}}",
  /* ANALYSES LIST */
  BACK_TO_ANALYSES_LIST: "Retour à la liste des analyses",
  ANALYSES_LIST_TITLE_DESCRIPTION: "Liste des analyses",
  ANALYSES_LIST_TITLE: "Liste des analyses",
  ANALYSES_LIST_HEADER_ALL_ANALYSES: "Toutes les analyses",
  ANALYSES_LIST_HEADER_ACTIVE_ANALYSES: "Les analyses actives",
  ANALYSES_LIST_COUNT_LABEL_zero: "{{count}} analyse",
  ANALYSES_LIST_COUNT_LABEL_one: "{{count}} analyse",
  ANALYSES_LIST_COUNT_LABEL_other: "{{count}} analyses",
  ANALYSES_LIST_SEARCH_ANALYSE_LABEL: "Rechercher une analyse",
  ANALYSES_LIST_ADD_NEW_ANALYSE_BUTTON: "Ajouter une analyse",
  ANALYSES_LIST_FILTER_ALL_ANALYSES: "Toutes",
  ANALYSES_LIST_FILTER_ACTIVE_ANALYSES: "Actives",
  ANALYSES_LIST_CARD_NAME_LABEL: "Nom",
  ANALYSES_LIST_CARD_KIT_LABEL: "Kit",
  ANALYSES_LIST_CARD_CHIP_LABEL: "Puce",
  ANALYSES_LIST_CARD_VERSION_LABEL: "Version",
  ANALYSES_LIST_CARD_IS_ACTIVE: "Active",
  ANALYSES_LIST_CARD_IS_INACTIVE: "Archivée",
  ANALYSES_LIST_CARD_SEE_DETAILS_LINK: "Voir en détails",
  ANALYSES_LIST_CARD_MENU_NEW_VERSION: "Nouvelle version",
  ANALYSES_LIST_CARD_MENU_ACTIVATE: "Activer",
  ANALYSES_LIST_CARD_MENU_DEACTIVATE: "Désactiver",
  ANALYSES_LIST_CARD_MENU_UPDATE: "Modifier",
  ANALYSES_LIST_CARD_MENU_DELETE: "Supprimer",
  ANALYSES_LIST_CARD_DELETION_MODAL_TITLE:
    "Suppression de l'analyse\n'{{analysisName}}'",
  ANALYSES_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Êtes-vous sûr de vouloir supprimer l'analyse '{{analysisName}}' ?",
  ANALYSES_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Supprimer l'analyse",
  ANALYSES_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Analyse supprimée avec succès",
  ANALYSES_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la suppression de l'analyse. Veuillez réessayer.",
  ANALYSES_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE: "Analyse activée avec succès",
  ANALYSES_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant l'activation de l'analyse. Veuillez réessayer.",
  ANALYSES_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE:
    "Analyse désactivée avec succès",
  ANALYSES_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la désactivation de l'analyse. Veuillez réessayer.",
  ANALYSES_LIST_CARD_NEW_VERSION_MODAL_TITLE:
    "Création d'une nouvelle version de l'analyse\n'{{analysisName}}'",
  ANALYSES_LIST_CARD_NEW_VERSION_MODAL_SUBTITLE:
    "Vous êtes sur le point de créer une nouvelle version de l'analyse '{{analysisName}}'. Renseignez le nouveau numéro de version puis validez.",
  ANALYSES_LIST_CARD_NEW_VERSION_MODAL_FORM_NEW_VERSION_LABEL:
    "Nouvelle version",
  ANALYSES_LIST_CARD_NEW_VERSION_MODAL_FORM_SUBMIT_BUTTON:
    "Créer nouvelle version",
  ANALYSES_LIST_CREATE_NEW_VERSION_SUCCESS_TOAST_MESSAGE:
    "Nouvelle version de l'analyse créée avec succès",
  ANALYSES_LIST_CREATE_NEW_VERSION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la création de la nouvelle version de l'analyse. Veuillez réessayer.",
  /* APP HOME */
  APP_HOME_TITLE_DESCRIPTION: "Application Geeng",
  APP_HOME_TITLE: "Portail d'applications",
  APP_HOME_WELCOME: "Bonjour, {{user}}",
  APP_HOME_TABS_LABEL:
    "Consultez la liste des applications ou retrouvez les dernières nouveautés",
  APP_HOME_TABS_APPLICATIONS: "Applications",
  APP_HOME_TABS_LATEST: "Récents",
  APP_HOME_APPLICATION_DPCR: "dPCR - Onco",
  APP_HOME_APPLICATION_DPCR_SUBTITLE: "Stilla",
  APP_HOME_APPLICATION_NGS_VIRO: "NGS - Viro",
  APP_HOME_APPLICATION_NGS_VIRO_SUBTITLE: "QIAGEN",
  APP_HOME_APPLICATION_NGS_ONCO: "NGS - Onco",
  APP_HOME_APPLICATION_NGS_ONCO_SUBTITLE: "FDGM",
  APP_HOME_APPLICATION_NGS_PRENAT: "NGS - Prenatal",
  APP_HOME_APPLICATION_NGS_PRENAT_SUBTITLE: "Genome",
  APP_HOME_APPLICATION_PCR_VIRO: "PCR - Viro",
  APP_HOME_APPLICATION_PCR_VIRO_SUBTITLE: "QIAGEN",
  APP_HOME_APPLICATION_PCR_FER: "PCR - FER",
  APP_HOME_APPLICATION_PCR_FER_SUBTITLE: "LaCAR",
  APP_HOME_SUBTABS_LABEL:
    "Consultez les dernières fonctionnalités ajoutées ou la liste des dernières actualités",
  APP_HOME_TABS_FEATURES: "Features",
  APP_HOME_TABS_NEWS: "Actualités",
  APP_HOME_FEATURE_DPCR_ANALYSIS_CREATION: "Parcours de création d'analyse",
  APP_HOME_FEATURE_DPCR_ANALYSIS_CREATION_DETAILS:
    "L'utilisateur a désormais la possibilité de créer sa propre analyse. En suivant chacune des étapes du parcours, il définit d'abord le kit/panel et la puce utilisée puis les paramètres de son analyse qui seront utilisés pour l'aider à interpréter les résultats des runs qui utiliseront cette analyse.",
  APP_HOME_FEATURE_DPCR_KITS_LISTING: "Liste des kits",
  APP_HOME_FEATURE_DPCR_KITS_LISTING_DETAILS:
    "L'utilisateur peut définir ses propres kits utilisés lors de ses analyses de Digital PCR. Il définit notamment la liste des cibles associée à ce kit et ensuite intervenir sur le cycle de vie du kit (activation/désactivation, modification, etc.).",
  APP_HOME_FEATURE_DPCR_CHIPS_LISTING: "Liste des puces",
  APP_HOME_FEATURE_DPCR_CHIPS_LISTING_DETAILS:
    "L'utilisateur peut définir ses propres puces utilisées lors de ses analyses de Digital PCR. Il définit notamment les caractéristiques physiques de la puce (colonnes, lignes, volumes de partition et de réaction, etc.) et ensuite intervenir sur le cycle de vie du kit (activation/désactivation, modification, etc.).",
  APP_HOME_FEATURE_DPCR_ANALYSES_LISTING: "Liste des analyses",
  APP_HOME_FEATURE_DPCR_ANALYSES_LISTING_DETAILS:
    "L'utilisateur peut consulter l'ensemble des analyses de Digital PCR disponibles pour ses runs. Il pourra ainsi intervenir sur le cycle de vie de l'analyse (activation/désactivation, modification, etc.).",
  APP_HOME_FEATURE_DPCR_SERIES_LISTING: "Liste des séries",
  APP_HOME_FEATURE_DPCR_SERIES_LISTING_DETAILS:
    "L'utilisateur peut désormais consulter la liste des séries effectuées ou en cours et accéder aux résultats des runs associés.",
  APP_HOME_FEATURE_DPCR_RUNS_LISTING: "Liste des runs",
  APP_HOME_FEATURE_DPCR_RUNS_LISTING_DETAILS:
    "L'utilisateur peut consulter les runs importés dans l'application et filtrer la liste suivant différents filtres afin de retrouver le ou les runs recherchés.",
  APP_HOME_FEATURE_DPCR_RUN_QC_VALIDATION:
    "Validation des contrôles qualité d'un run",
  APP_HOME_FEATURE_DPCR_RUN_QC_VALIDATION_DETAILS:
    "Une fois le run importé dans l'application, l'utilisateur peut valider les contrôles qualité du run. Il s'agit de la première étape en amont de la validation des échantillons contenus dans ce run. Si les contrôles qualité du run ne sont pas satisfaisants, alors les résultats des échantillons ne seront probablement pas contributifs.",
  APP_HOME_FEATURE_DPCR_SAMPLES_LISTING: "Liste des échantillons d'une série",
  APP_HOME_FEATURE_DPCR_SAMPLES_LISTING_DETAILS:
    "L'utilisateur peut visualiser la liste des échantillons contenus dans une série et les filtrer selon différents paramètres afin de rapidement retrouver un échantillon recherché ou le nombre d'échantillons restants à valider par exemple.",
  APP_HOME_FEATURE_DPCR_SAMPLES_VALIDATION: "Validation d'un échantillon",
  APP_HOME_FEATURE_DPCR_SAMPLES_VALIDATION_DETAILS:
    "Il s'agit de l'une des fonctionnalités principales de l'application : la validation des résultats des analyses d'un échantillon. L'utilisateur peut ainsi interpréter les résultats des analyses d'un échantillon depuis l'interface l'aidant à prendre les décisions d'interprétation.",
  /* BREADCRUMBS */
  BREADCRUMBS_ADMINISTRATION: "Administration",
  BREADCRUMBS_ANALYSES: "Analyses",
  BREADCRUMBS_ANALYSES_ID: "Analyse - {{id}}",
  BREADCRUMBS_BATCHES_MANAGEMENT: "Gestion des lots",
  BREADCRUMBS_BATCHES_MANAGEMENT_ID: "Lot - {{id}}",
  BREADCRUMBS_CHIPS: "Puces",
  BREADCRUMBS_CHIPS_ID: "Puce - {{id}}",
  BREADCRUMBS_CREATE: "Ajout",
  BREADCRUMBS_NEW: "Ajout",
  BREADCRUMBS_DEFAULT_ID: "ID - {{id}}",
  BREADCRUMBS_EDIT: "Édition",
  BREADCRUMBS_KITS: "Kits",
  BREADCRUMBS_KITS_ID: "Kit - {{id}}",
  BREADCRUMBS_NOTIFICATIONS: "Notifications",
  BREADCRUMBS_RUNS: "Runs",
  BREADCRUMBS_SAMPLE_ID: "Échantillon - {{id}}",
  BREADCRUMBS_SERIES: "Séries",
  BREADCRUMBS_SERIES_ID: "Série - {{id}}",
  BREADCRUMBS_SOFTWARES: "Logiciels",
  BREADCRUMBS_USERS: "Utilisateurs",
  BREADCRUMBS_SEARCH_INPUT_LABEL: "Recherche d'échantillon",
  BREADCRUMBS_SEARCH_INPUT_LABEL_NO_OPTION_MESSAGE: "Aucun échantillon trouvé",
  BREADCRUMBS_SEARCH_INPUT_LABEL_ITEM_PATHOLOGY: "Patho.",
  BREADCRUMBS_SEARCH_INPUT_LABEL_ITEM_TARGETS_zero: "Cible",
  BREADCRUMBS_SEARCH_INPUT_LABEL_ITEM_TARGETS_one: "Cible",
  BREADCRUMBS_SEARCH_INPUT_LABEL_ITEM_TARGETS_other: "Cibles",
  /* CALCULATION */
  VAF: "Calcul de la VAF",
  SENSITIVITY: "Calcul de la sensitivité",
  UNCERTAINTY: "Incertitude",
  CALCULATION_TOOLTIP_ERROR:
    "{{type}}: une erreur est survenue pendant le calcul",
  /* CHIPS CREATION */
  CHIPS_CREATION_TITLE_DESCRIPTION: "Création d'une puce",
  CHIPS_CREATION_TITLE: "Création d'une puce",
  CHIPS_CREATION_SUBTITLE:
    "Définissez-ici les informations sur la puce : un nom, le fournisseur, un numéro de version et ses caractéristiques physiques",
  CHIPS_CREATION_SUCCESSFUL_TOAST_MESSAGE: "Puce créée avec succès",
  CHIPS_CREATION_FORM_SUBMIT_BUTTON_LABEL: "Créer la puce",
  CHIPS_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  CHIPS_CREATION_FORM_INFORMATIONS_SUBTITLE:
    "Informations générales de la puce",
  CHIPS_CREATION_FORM_FORMAT_NB_CHAMBERS_SUMMARY: "Nb puits",
  CHIPS_CREATION_FORM_NAME_LABEL: "Nom",
  CHIPS_CREATION_FORM_SUPPLIER_LABEL: "Fournisseur",
  CHIPS_CREATION_FORM_SUPPLIER_NO_OPTION: "Aucun fournisseur trouvé",
  CHIPS_CREATION_FORM_SUPPLIER_PLACEHOLDER: "Sélectionner un fournisseur",
  CHIPS_CREATION_FORM_VERSION_LABEL: "Version",
  CHIPS_CREATION_FORM_FORMAT_TITLE: "Format de la puce",
  CHIPS_CREATION_FORM_FORMAT_SUBTITLE: "Caractéristiques physiques de la puce",
  CHIPS_CREATION_FORM_NB_COLS_LABEL: "Nombre de colonnes",
  CHIPS_CREATION_FORM_NB_ROWS_LABEL: "Nombre de lignes",
  CHIPS_CREATION_FORM_NB_MAX_PARTITIONS_LABEL: "Partitions par puit",
  CHIPS_CREATION_FORM_PARTITION_VOLUME_LABEL: "Volume de la partition",
  CHIPS_CREATION_FORM_PARTITION_VOLUME_UNIT_LABEL: "nL",
  CHIPS_CREATION_FORM_INPUT_VOLUME_LABEL: "Volume de réaction",
  CHIPS_CREATION_FORM_INPUT_VOLUME_UNIT_LABEL: "μL",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_TITLE: "Règles de contrôles",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_SUBTITLE:
    "Quantité de partitions suffisante pour valider la conformité des échantillons",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_LABEL: "Quantité de partitions requise",
  CHIPS_CREATION_FORM_QUALITY_CONTROL_SUPERIOR_OR_EQUAL_LABEL: "≥",
  CHIPS_CREATION_CONFIRMATION_MODAL_TITLE: "Création d'une puce",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUBTITLE:
    "Vous êtes sur le point de créer la puce {{chipName}}. Veuillez vérifier les informations saisies avant de confirmer la création.",
  CHIPS_CREATION_CONFIRMATION_MODAL_NAME_LABEL: "Nom",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUPPLIER_LABEL: "Fournisseur",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_LABEL: "Format",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_zero:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_one:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_CREATION_CONFIRMATION_MODAL_FORMAT_VALUE_other:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puits",
  CHIPS_CREATION_CONFIRMATION_MODAL_PARTITION_VOLUME_LABEL: "V. partition",
  CHIPS_CREATION_CONFIRMATION_MODAL_PARTITION_VOLUME_VALUE: "{{value}} nL",
  CHIPS_CREATION_CONFIRMATION_MODAL_INPUT_VOLUME_LABEL: "V. réaction",
  CHIPS_CREATION_CONFIRMATION_MODAL_INPUT_VOLUME_VALUE: "{{value}} μL",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_LABEL:
    "Conformité des contrôles qualité",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_VALUE_one:
    "à partir de {{count}} partition",
  CHIPS_CREATION_CONFIRMATION_MODAL_QUALITY_CONTROL_VALUE_other:
    "à partir de {{count}} partitions",
  CHIPS_CREATION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirmer la création",
  /* CHIPS DETAILS */
  CHIPS_DETAILS_CHIP_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Puce non trouvée. Retour à la liste des puces",
  CHIPS_DETAILS_TITLE_DESCRIPTION: "Informations de la puce '{{chipName}}'",
  CHIPS_DETAILS_TITLE: "Informations de la puce '{{chipName}}'",
  CHIPS_DETAILS_INFORMATIONS_TITLE: "Informations",
  CHIPS_DETAILS_INFORMATIONS_SUBTITLE: "Informations générales sur la puce",
  CHIPS_DETAILS_NAME_LABEL: "Nom",
  CHIPS_DETAILS_SUPPLIER_LABEL: "Fournisseur",
  CHIPS_DETAILS_VERSION_LABEL: "Version",
  CHIPS_DETAILS_CREATION_LABEL: "Création",
  CHIPS_DETAILS_CREATION_DATE_VALUE: "Le {{- date}}",
  CHIPS_DETAILS_CREATION_VALUE: "Le {{- date}}, par {{user}}",
  CHIPS_DETAILS_LAST_MODIFICATION_LABEL: "Dernière modification",
  CHIPS_DETAILS_LAST_MODIFICATION_DATE_VALUE: "Le {{- date}}",
  CHIPS_DETAILS_LAST_MODIFICATION_VALUE: "Le {{- date}}, par {{user}}",
  CHIPS_DETAILS_FORMAT_TITLE: "Format de la puce",
  CHIPS_DETAILS_FORMAT_SUBTITLE: "Caractéristiques physiques de la puce",
  CHIPS_DETAILS_FORMAT_NB_CHAMBERS_SUMMARY: "Nb puits",
  CHIPS_DETAILS_FORMAT_LABEL: "Format",
  CHIPS_DETAILS_FORMAT_VALUE_zero:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_DETAILS_FORMAT_VALUE_one: "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_DETAILS_FORMAT_VALUE_other:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puits",
  CHIPS_DETAILS_INPUT_VOLUME_LABEL: "V. réaction",
  CHIPS_DETAILS_INPUT_VOLUME_VALUE: "{{inputVolume}} μL",
  CHIPS_DETAILS_PARTITION_VOLUME_LABEL: "V. partition",
  CHIPS_DETAILS_PARTITION_VOLUME_VALUE: "{{partitionVolume}} nL",
  CHIPS_DETAILS_QUALITY_CONTROL_LABEL: "Conformité des contrôles qualité",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_zero:
    "à partir de {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_one:
    "à partir de {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_VALUE_other:
    "à partir de {{count}} partitions / {{nbMaxPartitions}}",
  CHIPS_DETAILS_QUALITY_CONTROL_TITLE: "Règles de contrôles",
  CHIPS_DETAILS_QUALITY_CONTROL_SUBTITLE:
    "Quantité de partitions suffisante pour valider la conformité des échantillons",
  CHIPS_DETAILS_EDIT_BUTTON_LABEL: "Modifier la puce",
  /* CHIPS EDITION */
  CHIPS_EDITION_TITLE_DESCRIPTION: "Édition de la puce {{chipName}}",
  CHIPS_EDITION_SUCCESSFUL_TOAST_MESSAGE: "Puce modifiée avec succès",
  CHIPS_EDITION_FORM_SUBMIT_BUTTON_LABEL: "Modifier la puce",
  CHIPS_EDITION_CONFIRMATION_MODAL_SUBTITLE:
    "Vous êtes sur le point de modifier la puce {{chipName}}. Veuillez vérifier les informations saisies avant de confirmer la modification.",
  CHIPS_EDITION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirmer la modification",
  /* CHIPS LITS */
  BACK_TO_CHIPS_LIST: "Retour à la liste des puces",
  CHIPS_LIST_TITLE_DESCRIPTION: "Liste des puces",
  CHIPS_LIST_TITLE: "Liste des puces",
  CHIPS_LIST_ADD_NEW_CHIP_BUTTON: "Ajouter une puce",
  CHIPS_LIST_SEARCH_CHIP_LABEL: "Rechercher une puce",
  CHIPS_LIST_COUNT_LABEL_zero: "{{count}} puce",
  CHIPS_LIST_COUNT_LABEL_one: "{{count}} puce",
  CHIPS_LIST_COUNT_LABEL_other: "{{count}} puces",
  CHIPS_LIST_HEADER_ALL_CHIPS: "Toutes les puces",
  CHIPS_LIST_HEADER_ACTIVE_CHIPS: "Les puces actives",
  CHIPS_LIST_FILTER_ALL_CHIPS: "Toutes",
  CHIPS_LIST_FILTER_ACTIVE_CHIPS: "Actives",
  CHIPS_LIST_FILTER_TABS_LABEL: "Filtrer les puces par activité",
  CHIPS_LIST_CARD_NAME_LABEL: "Nom",
  CHIPS_LIST_CARD_SUPPLIER_LABEL: "Fournisseur",
  CHIPS_LIST_CARD_FORMAT_LABEL: "Format",
  CHIPS_LIST_CARD_FORMAT_VALUE_zero:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_LIST_CARD_FORMAT_VALUE_one:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puit",
  CHIPS_LIST_CARD_FORMAT_VALUE_other:
    "{{nbRows}} L / {{nbCols}} C, {{count}} puits",
  CHIPS_LIST_CARD_PARTITIONS_NUMBER_LABEL: "Partitions par puit",
  CHIPS_LIST_CARD_INPUT_VOLUME_LABEL: "V. réaction",
  CHIPS_LIST_CARD_INPUT_VOLUME_VALUE: "{{inputVolume}} μL",
  CHIPS_LIST_CARD_PARTITION_VOLUME_LABEL: "V. partition",
  CHIPS_LIST_CARD_PARTITION_VOLUME_VALUE: "{{partitionVolume}} nL",
  CHIPS_LIST_CARD_IS_ACTIVE: "Active",
  CHIPS_LIST_CARD_IS_INACTIVE: "Archivée",
  CHIPS_LIST_CARD_MENU_BUTTON_LABEL: "Actions pour la puce",
  CHIPS_LIST_CARD_MENU_NEW_VERSION: "Nouvelle version",
  CHIPS_LIST_CARD_MENU_ACTIVATE: "Activer",
  CHIPS_LIST_CARD_MENU_DEACTIVATE: "Archiver",
  CHIPS_LIST_CARD_MENU_DELETE: "Supprimer",
  CHIPS_LIST_CARD_VERSION_LABEL: "Version",
  CHIPS_LIST_CARD_QUALITY_CONTROL_LABEL: "Nombre minimum de partitions",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_zero:
    "> {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_one:
    "> {{count}} partition / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_QUALITY_CONTROL_VALUE_other:
    "> {{count}} partitions / {{nbMaxPartitions}}",
  CHIPS_LIST_CARD_SEE_DETAILS_LINK: "Voir en détails",
  CHIPS_LIST_CARD_DELETION_MODAL_TITLE:
    "Suppression de la puce\n'{{chipName}}'",
  CHIPS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Êtes-vous sûr de vouloir supprimer la puce '{{chipName}}' ?",
  CHIPS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Supprimer la puce",
  CHIPS_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Puce supprimée avec succès",
  CHIPS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la suppression de la puce. Veuillez réessayer.",
  CHIPS_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE: "Puce activée avec succès",
  CHIPS_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant l'activation de la puce. Veuillez réessayer.",
  CHIPS_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE: "Puce désactivée avec succès",
  CHIPS_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la désactivation de la puce. Veuillez réessayer.",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_TITLE:
    "Création d'une nouvelle version de la puce\n'{{chipName}}'",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_SUBTITLE:
    "Vous êtes sur le point de créer une nouvelle version de la puce '{{chipName}}'. Renseignez le nouveau numéro de version puis validez.",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_FORM_NEW_VERSION_LABEL: "Nouvelle version",
  CHIPS_LIST_CARD_NEW_VERSION_MODAL_FORM_SUBMIT_BUTTON:
    "Créer nouvelle version",
  CHIPS_LIST_CREATE_NEW_VERSION_SUCCESS_TOAST_MESSAGE:
    "Nouvelle version de la puce créée avec succès",
  CHIPS_LIST_CREATE_NEW_VERSION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la création de la nouvelle version de la puce. Veuillez réessayer.",
  /* CONTACT */
  CONTACT_TITLE_DESCRIPTION: "Contacter l'équipe Geeng",
  CONTACT_TITLE: "Contacter l'équipe Geeng",
  CONTACT_SUBTITLE:
    "Remplissez le formulaire de contact. Nous reviendrons vers vous le plus rapidement possible.",
  CONTACT_FORM_USER_INFORMATIONS_TITLE: "Informations",
  CONTACT_FORM_USER_INFORMATIONS_SUBTITLE: "Informations sur l'utilisateur",
  CONTACT_FORM_NAME_LABEL: "Nom",
  CONTACT_FORM_MAIL_LABEL: "Adresse mail",
  CONTACT_FORM_MESSAGE_TITLE: "Votre message",
  CONTACT_FORM_SUBMIT_BUTTON_LABEL: "Envoyer",
  CONTACT_FORM_SUCCESSFUL_TOAST_MESSAGE:
    "Message envoyé avec succès (aucune action en base de données pour le moment)",
  /* DPCR HOME */
  DPCR_HOME_TITLE_DESCRIPTION: "Application DPCR",
  DPCR_HOME_TITLE: "Application DPCR",
  DPCR_HOME_YOUR_SERIES: "Vos séries et runs dPCR",
  DPCR_HOME_YOUR_MATERIALS: "Vos outils d'analyse dPCR",
  DPCR_HOME_SERIES_COUNT_zero: "{{count}} série",
  DPCR_HOME_SERIES_COUNT_one: "{{count}} série",
  DPCR_HOME_SERIES_COUNT_other: "{{count}} séries",
  DPCR_HOME_SERIES_SUBTITLE:
    "Interprétez les résultats d'analyse de vos échantillons",
  DPCR_HOME_RUNS_COUNT_zero: "{{count}} run",
  DPCR_HOME_RUNS_COUNT_one: "{{count}} run",
  DPCR_HOME_RUNS_COUNT_other: "{{count}} runs",
  DPCR_HOME_RUNS_SUBTITLE: "Suivez la validation de vos échantillons",
  DPCR_HOME_ANALYSES_COUNT_zero: "{{count}} analyse",
  DPCR_HOME_ANALYSES_COUNT_one: "{{count}} analyse",
  DPCR_HOME_ANALYSES_COUNT_other: "{{count}} analyses",
  DPCR_HOME_ANALYSES_SUBTITLE:
    "Ajoutez, supprimez, mettez à jour l'une des analyses de votre laboratoire",
  DPCR_HOME_KITS_COUNT_zero: "{{count}} kit",
  DPCR_HOME_KITS_COUNT_one: "{{count}} kit",
  DPCR_HOME_KITS_COUNT_other: "{{count}} kits",
  DPCR_HOME_KITS_SUBTITLE:
    "Ajoutez, supprimez, mettez à jour l'un des kits de votre laboratoire",
  DPCR_HOME_CHIPS_COUNT_zero: "{{count}} puce",
  DPCR_HOME_CHIPS_COUNT_one: "{{count}} puce",
  DPCR_HOME_CHIPS_COUNT_other: "{{count}} puces",
  DPCR_HOME_CHIPS_SUBTITLE:
    "Ajoutez, supprimez, mettez à jour l'une des puces de votre laboratoire",
  /* DPCR_SIDEBAR */
  DPCR_SIDEBAR_SEARCH_INPUT_HELPERTEXT: "Rechercher",
  DPCR_SIDEBAR_SEARCH_INPUT_LABEL:
    "Rechercher par numéro d'échantillon ou mutation",
  DPCR_SIDEBAR_SEARCH_INPUT_NO_OPTION: "Aucun résultat trouvé",
  DPCR_SIDEBAR_ITEMS_DASHBOARD: "Dashboard",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS: "Séries / Runs",
  DPCR_SIDEBAR_ITEMS_RUNS_LIST: "Liste Runs",
  DPCR_SIDEBAR_ITEMS_SERIES_LIST: "Liste Séries",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS_CREATE: "Créer nouvelle série",
  DPCR_SIDEBAR_ITEMS_SERIES_RUNS_UPLOAD_RESULTS: "Upload des résultats",
  DPCR_SIDEBAR_ITEMS_ANALYSES: "Analyses",
  DPCR_SIDEBAR_ITEMS_ANALYSES_LIST: "Liste des Analyses",
  DPCR_SIDEBAR_ITEMS_ANALYSES_BATCH_MANAGEMENT: "Gestion des Lots",
  DPCR_SIDEBAR_ITEMS_NOTIFICATIONS: "Notifications",
  DPCR_SIDEBAR_ITEMS_SETTINGS: "Configuration",
  DPCR_SIDEBAR_ITEMS_SETTINGS_ANALYSIS_CREATE: "Créer nouvelle analyse",
  DPCR_SIDEBAR_ITEMS_SETTINGS_KITS_SEE: "Liste des kits",
  DPCR_SIDEBAR_ITEMS_SETTINGS_KITS_CREATE: "Créer nouveau kit",
  DPCR_SIDEBAR_ITEMS_SETTINGS_CHIPS_SEE: "Liste des puces",
  DPCR_SIDEBAR_ITEMS_SETTINGS_CHIPS_CREATE: "Créer nouvelle puce",
  DPCR_SIDEBAR_ITEMS_SETTINGS_SOFTWARES_SEE: "Liste des logiciels",
  DPCR_SIDEBAR_ITEMS_ADMINISTRATION: "Administration",
  DPCR_SIDEBAR_ITEMS_LOGOUT: "Déconnexion",
  /* ENTITIES */
  ANALYSIS: "Analyse",
  /* ERRORS */
  ERROR_NOT_ALLOWED_KIT_BATCH_ANALYSIS_MISMATCH:
    "Le numéro de lot et l'analyse doivent être liés au même kit",
  ERROR_INTERNAL_SERVER_ERROR: "Une erreur s'est produite.",
  ERROR_NOT_FOUND: "Entité non trouvée.",
  ERROR_NOT_FOUND_VARIABLE_MALE_zero: "{{entity}} non trouvé.",
  ERROR_NOT_FOUND_VARIABLE_MALE_one: "{{entity}} non trouvé.",
  ERROR_NOT_FOUND_VARIABLE_MALE_other: "{{entity}} non trouvés.",
  ERROR_NOT_FOUND_VARIABLE_FEMALE_zero: "{{entity}} non trouvée.",
  ERROR_NOT_FOUND_VARIABLE_FEMALE_one: "{{entity}} non trouvée.",
  ERROR_NOT_FOUND_VARIABLE_FEMALE_other: "{{entity}} non trouvées.",
  ERROR_PAYLOAD_VALIDATION_ERROR_LIST_TYPE:
    "Le champ n'a pas le format de liste attendu",
  ERROR_UNIQUE_CONSTRAINT_VIOLATION:
    "Une entité avec les mêmes valeurs existe déjà",
  /* FORM */
  FORM_REQUIRED_FIELD: "Champ requis",
  FORM_REQUIRED_AT_LEAST_ONE_ELEMENT:
    "Ce champ doit contenir au moins 1 élément",
  FORM_NO_OPTION_FOUND: "Aucune option trouvée",
  /* INTERPRETATIONS */
  MUTATED_zero: "Muté",
  MUTATED_one: "Muté",
  MUTATED_other: "Mutés",
  UNMUTATED_zero: "Non Muté",
  UNMUTATED_one: "Non Muté",
  UNMUTATED_other: "Non Mutés",
  /* KITS CREATION */
  KITS_CREATION_TITLE_DESCRIPTION: "Création d'un kit",
  KITS_CREATION_TITLE: "Création d'un kit",
  KITS_CREATION_SUBTITLE:
    "Définissez-ici les informations sur le kit : un nom, le fournisseur, un numéro de version, la liste des matrices d'échantillons sur lesquelles il peut être utilisé et la liste des cibles.",
  KITS_CREATION_FORM_NAME_LABEL: "Nom",
  KITS_CREATION_FORM_SUPPLIER_LABEL: "Fournisseur",
  KITS_CREATION_FORM_SUPPLIER_NO_OPTION: "Aucun fournisseur trouvé",
  KITS_CREATION_FORM_SUPPLIER_PLACEHOLDER: "Sélectionner un fournisseur",
  KITS_CREATION_FORM_VERSION_LABEL: "Version",
  KITS_CREATION_FORM_MATRICES_LABEL: "Matrices",
  KITS_CREATION_FORM_MATRICES_PLACEHOLDER: "Sélectionner les matrices",
  KITS_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  KITS_CREATION_FORM_INFORMATIONS_SUBTITLE: "Informations générales sur le kit",
  KITS_CREATION_FORM_TARGETS_LABEL: "Nom de la cible",
  KITS_CREATION_FORM_TARGETS_TITLE: "Cibles",
  KITS_CREATION_FORM_TARGETS_SUBTITLE: "Liste des cibles couvertes par le kit",
  KITS_CREATION_FORM_TARGETS_NO_OPTION: "Aucune cible trouvée",
  KITS_CREATION_FORM_TARGETS_PLACEHOLDER: "Renseigner son nom",
  KITS_CREATION_FORM_TARGETS_ADD_BUTTON: "Ajouter une cible",
  KITS_CREATION_FORM_TARGETS_TARGET_INDEX: "Cible n°{{index}}",
  KITS_CREATION_FORM_TARGETS_SUBMIT: "Créer le kit",
  KITS_CREATION_FORM_TARGETS_SUMMARY_zero: "{{count}} Cible",
  KITS_CREATION_FORM_TARGETS_SUMMARY_one: "{{count}} Cible",
  KITS_CREATION_FORM_TARGETS_SUMMARY_other: "{{count}} Cibles",
  KITS_CREATION_SUCCESS_TOAST_MESSAGE: "Kit créé avec succès",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_zero:
    "Création d'un kit avec {{count}} Cible",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_one:
    "Création d'un kit avec {{count}} Cible",
  KITS_CREATION_CONFIRMATION_MODAL_TITLE_other:
    "Création d'un kit avec {{count}} Cibles",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_zero:
    "Vous êtes sur le point de créer le kit {{kitName}} avec un panel de {{count}} cible. Veuillez vérifier les informations saisies avant de confirmer la création.",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_one:
    "Vous êtes sur le point de créer le kit {{kitName}} avec un panel de {{count}} cible. Veuillez vérifier les informations saisies avant de confirmer la création.",
  KITS_CREATION_CONFIRMATION_MODAL_SUBTITLE_other:
    "Vous êtes sur le point de créer le kit {{kitName}} avec un panel de {{count}} cibles. Veuillez vérifier les informations saisies avant de confirmer la création.",
  KITS_CREATION_CONFIRMATION_MODAL_NAME_LABEL: "Nom",
  KITS_CREATION_CONFIRMATION_MODAL_TARGETS_LABEL: "Nb Cibles",
  KITS_CREATION_CONFIRMATION_MODAL_SUPPLIER_LABEL: "Fournisseur",
  KITS_CREATION_CONFIRMATION_MODAL_VERSION_LABEL: "Version",
  KITS_CREATION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirmer la création",
  /* KITS DETAILS */
  KITS_DETAILS_TITLE_DESCRIPTION: "Informations du kit '{{kitName}}'",
  KITS_DETAILS_TITLE: "Informations du kit '{{kitName}}'",
  KITS_DETAILS_INFORMATIONS_TITLE: "Informations",
  KITS_DETAILS_INFORMATIONS_SUBTITLE: "Informations générales sur le kit",
  KITS_DETAILS_NAME_LABEL: "Nom",
  KITS_DETAILS_SUPPLIER_LABEL: "Fournisseur",
  KITS_DETAILS_VERSION_LABEL: "Version",
  KITS_DETAILS_MATRICES_LABEL: "Matrices",
  KITS_DETAILS_CREATION_LABEL: "Création",
  KITS_DETAILS_CREATION_DATE_VALUE: "Le {{- date}}",
  KITS_DETAILS_CREATION_VALUE: "Le {{- date}}, par {{user}}",
  KITS_DETAILS_LAST_MODIFICATION_LABEL: "Dernière modification",
  KITS_DETAILS_LAST_MODIFICATION_DATE_VALUE: "Le {{- date}}",
  KITS_DETAILS_LAST_MODIFICATION_VALUE: "Le {{- date}}, par {{user}}",
  KITS_DETAILS_TARGETS_TITLE: "Toutes les cibles du kit",
  KITS_DETAILS_TARGETS_COUNT_LABEL_zero: "{{count}} Cible",
  KITS_DETAILS_TARGETS_COUNT_LABEL_one: "{{count}} Cible",
  KITS_DETAILS_TARGETS_COUNT_LABEL_other: "{{count}} Cibles",
  KITS_DETAILS_KIT_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Kit non trouvé. Retour à la liste des kits",
  KITS_DETAILS_EDIT_BUTTON_LABEL: "Modifier le kit",
  /* KITS EDITION */
  KITS_EDITION_SUCCESSFUL_TOAST_MESSAGE: "Kit mis à jour avec succès",
  KITS_EDITION_TITLE_DESCRIPTION: "Édition du kit {{kitName}}",
  KITS_EDITION_FORM_SUBMIT_BUTTON_LABEL: "Modifier le kit",
  KITS_EDITION_CONFIRMATION_MODAL_SUBTITLE_zero:
    "Vous êtes sur le point de modifier le kit {{kitName}} avec un panel de {{count}} Cible. Veuillez vérifier les informations saisies avant de confirmer la modification.",
  KITS_EDITION_CONFIRMATION_MODAL_SUBTITLE_one:
    "Vous êtes sur le point de modifier le kit {{kitName}} avec un panel de {{count}} Cible. Veuillez vérifier les informations saisies avant de confirmer la modification.",
  KITS_EDITION_CONFIRMATION_MODAL_SUBTITLE_other:
    "Vous êtes sur le point de modifier le kit {{kitName}} avec un panel de {{count}} Cibles. Veuillez vérifier les informations saisies avant de confirmer la modification.",
  KITS_EDITION_CONFIRMATION_MODAL_SUBMIT_BUTTON: "Confirmer la modification",
  /* KITS LITS */
  BACK_TO_KITS_LIST: "Retour à la liste des kits",
  KITS_LIST_TITLE_DESCRIPTION: "Liste des kits",
  KITS_LIST_TITLE: "Liste des kits",
  KITS_LIST_ADD_NEW_KIT_BUTTON: "Ajouter un kit",
  KITS_LIST_FILTER_TABS_LABEL: "Filtrer les kits par activité",
  KITS_LIST_HEADER_ALL_KITS: "Tous les kits",
  KITS_LIST_HEADER_ACTIVE_KITS: "Les kits actifs",
  KITS_LIST_FILTER_ALL_KITS: "Tous",
  KITS_LIST_FILTER_ACTIVE_KITS: "Actifs",
  KITS_LIST_SEARCH_KIT_LABEL: "Rechercher un kit",
  KITS_LIST_COUNT_LABEL_zero: "{{count}} kit",
  KITS_LIST_COUNT_LABEL_one: "{{count}} kit",
  KITS_LIST_COUNT_LABEL_other: "{{count}} kits",
  KITS_LIST_CARD_NAME_LABEL: "Nom",
  KITS_LIST_CARD_SUPPLIER_LABEL: "Fournisseur",
  KITS_LIST_CARD_TARGETS_LABEL: "Nb Cibles",
  KITS_LIST_CARD_MATRICES_LABEL: "Matrices",
  KITS_LIST_CARD_VERSION_LABEL: "Version",
  KITS_LIST_CARD_IS_ACTIVE: "Actif",
  KITS_LIST_CARD_IS_INACTIVE: "Archivé",
  KITS_LIST_CARD_MENU_BUTTON_LABEL: "Actions pour le kit",
  KITS_LIST_CARD_MENU_NEW_VERSION: "Nouvelle version",
  KITS_LIST_CARD_MENU_DEACTIVATE: "Archiver",
  KITS_LIST_CARD_MENU_ACTIVATE: "Activer",
  KITS_LIST_CARD_MENU_DELETE: "Supprimer",
  KITS_LIST_CARD_MENU_CREATE_FROM: "Créer à partir de",
  KITS_LIST_CARD_SEE_DETAILS_LINK: "Voir en détails",
  KITS_LIST_CARD_DELETION_MODAL_TITLE: "Suppression du kit\n'{{kitName}}'",
  KITS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Êtes-vous sûr de vouloir supprimer le kit '{{kitName}}' ?",
  KITS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Supprimer le kit",
  KITS_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Kit supprimé avec succès",
  KITS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la suppression du kit. Veuillez réessayer.",
  KITS_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE: "Kit activé avec succès",
  KITS_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant l'activation du kit. Veuillez réessayer.",
  KITS_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE: "Kit désactivé avec succès",
  KITS_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la désactivation du kit. Veuillez réessayer.",
  KITS_LIST_CARD_NEW_VERSION_MODAL_TITLE:
    "Création d'une nouvelle version du kit\n'{{kitName}}'",
  KITS_LIST_CARD_NEW_VERSION_MODAL_SUBTITLE:
    "Vous êtes sur le point de créer une nouvelle version du kit {{kitName}}. Renseignez le nouveau numéro de version puis validez.",
  KITS_LIST_CARD_NEW_VERSION_MODAL_FORM_NEW_VERSION_LABEL: "Nouvelle version",
  KITS_LIST_CARD_NEW_VERSION_MODAL_FORM_SUBMIT_BUTTON: "Créer nouvelle version",
  KITS_LIST_CREATE_NEW_VERSION_SUCCESS_TOAST_MESSAGE:
    "Nouvelle version du kit créée avec succès",
  KITS_LIST_CREATE_NEW_VERSION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la création de la nouvelle version du kit. Veuillez réessayer.",
  /* KIT BATCHES CREATION */
  KIT_BATCHES_CREATION_TITLE_DESCRIPTION: "Création d'un nouveau lot",
  KIT_BATCHES_CREATION_TITLE: "Création d'un nouveau lot",
  KIT_BATCHES_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  KIT_BATCHES_CREATION_FORM_INFORMATIONS_SUBTITLE:
    "Informations générales du lot",
  KIT_BATCHES_CREATION_FORM_BATCH_NUMBER_LABEL: "Numéro du lot",
  KIT_BATCHES_CREATION_FORM_ANALYSIS_LABEL: "Analyse associée",
  KIT_BATCHES_CREATION_FORM_ANALYSIS_PLACEHOLDER: "Sélectionner l'analyse",
  KIT_BATCHES_CREATION_FORM_ANALYSIS_NO_OPTION: "Aucune analyse trouvée",
  KIT_BATCHES_CREATION_FORM_EXPIRY_LABEL: "Date de péremption",
  KIT_BATCHES_CREATION_FORM_IS_ACTIVE_LABEL: "Statut d'activité",
  KIT_BATCHES_CREATION_FORM_IS_ACTIVE_DESCRIPTION:
    "Par défaut le lot est actif, vous pouvez choisir de le rendre inactif dès maintenant ou pourrez modifier ce statut après la création",
  KIT_BATCHES_CREATION_FORM_TARGETS_TITLE: "Cibles associées au lot",
  KIT_BATCHES_CREATION_FORM_TARGETS_DESCRIPTION:
    "Définissez pour chaque cibles la borne minimale et maximale d'acception pour la validation des contrôles de ce lot",
  KIT_BATCHES_CREATION_FORM_TARGETS_NO_SELECTION:
    "Sélectionnez d'abord l'analyse pour afficher les cibles associées au lot",
  KIT_BATCHES_CREATION_FORM_THRESHOLDS_LOWER_THRESHOLD: "Borne Min.",
  KIT_BATCHES_CREATION_FORM_THRESHOLDS_UNDEFINED_PLACEHOLDER: "Non défini",
  KIT_BATCHES_CREATION_FORM_THRESHOLDS_LOWER_THRESHOLD_BADGE_LABEL:
    "Min. {{validated}}/{{expected}}",
  KIT_BATCHES_CREATION_FORM_THRESHOLDS_UPPER_THRESHOLD: "Borne Max.",
  KIT_BATCHES_CREATION_FORM_THRESHOLDS_UPPER_THRESHOLD_BADGE_LABEL:
    "Max. {{validated}}/{{expected}}",
  KIT_BATCHES_CREATION_FORM_SUBMIT: "Créer le lot",
  /* KIT BATCHES EDITION CONFIRMATION MODAL */
  KIT_BATCHES_CONFIRMATION_MODAL_WARNING_TITLE_zero:
    "Attention: {{count}}/{{expected}} borne indiquée",
  KIT_BATCHES_CONFIRMATION_MODAL_WARNING_TITLE_one:
    "Attention: {{count}}/{{expected}} borne indiquée",
  KIT_BATCHES_CONFIRMATION_MODAL_WARNING_TITLE_other:
    "Attention: {{count}}/{{expected}} bornes indiquées",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_zero:
    "Vous êtes sur le point de modifier le lot {{kitBatchName}}. Cependant, vous n'avez renseigné que {{count}} borne sur {{expected}}. La définition de ces valeurs ont un impact sur la validation des contrôles dans les analyses qui utiliseront ce lot.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_one:
    "Vous êtes sur le point de modifier le lot {{kitBatchName}}. Cependant, vous n'avez renseigné que {{count}} borne sur {{expected}}. La définition de ces valeurs ont un impact sur la validation des contrôles dans les analyses qui utiliseront ce lot.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_other:
    "Vous êtes sur le point de modifier le lot {{kitBatchName}}. Cependant, vous n'avez renseigné que {{count}} bornes sur {{expected}}. La définition de ces valeurs ont un impact sur la validation des contrôles dans les analyses qui utiliseront ce lot.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_WARNING_QUESTION:
    "Êtes-vous sûr de vouloir poursuivre la modification ?",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_SUBTITLE_zero:
    "Vous êtes sur le point de modifier le lot {{kitBatchName}} avec un panel de {{count}} cible. Veuillez vérifier les informations saisies avant de confirmer la modification.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_SUBTITLE_one:
    "Vous êtes sur le point de modifier le lot {{kitBatchName}} avec un panel de {{count}} cible. Veuillez vérifier les informations saisies avant de confirmer la modification.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_SUBTITLE_other:
    "Vous êtes sur le point de modifier le lot {{kitBatchName}} avec un panel de {{count}} cibles. Veuillez vérifier les informations saisies avant de confirmer la modification.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_NAME_LABEL: "Numéro du lot",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_ANALYSIS_LABEL: "Analyse associée",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_TARGETS_LABEL:
    "Nombre de cibles associées",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_EXPIRY_LABEL: "Date de péremption",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_IS_ACTIVE_LABEL: "Statut d'activité",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_SUBMIT_BUTTON:
    "Confirmer la création",
  KIT_BATCHES_EDITION_SUCCESSFUL_TOAST_MESSAGE: "Lot mis à jour avec succès",
  /* KIT BATCHES CREATION CONFIRMATION MODAL */
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_TITLE:
    "Création du lot '{{kitBatchName}}'",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_zero:
    "Vous êtes sur le point de créer le lot {{kitBatchName}}. Cependant, vous n'avez renseigné que {{count}} borne sur {{expected}}. La définition de ces valeurs ont un impact sur la validation des contrôles dans les analyses qui utiliseront ce lot.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_one:
    "Vous êtes sur le point de créer le lot {{kitBatchName}}. Cependant, vous n'avez renseigné que {{count}} borne sur {{expected}}. La définition de ces valeurs ont un impact sur la validation des contrôles dans les analyses qui utiliseront ce lot.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_WARNING_DESCRIPTION_other:
    "Vous êtes sur le point de créer le lot {{kitBatchName}}. Cependant, vous n'avez renseigné que {{count}} bornes sur {{expected}}. La définition de ces valeurs ont un impact sur la validation des contrôles dans les analyses qui utiliseront ce lot.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_WARNING_QUESTION:
    "Êtes-vous sûr de vouloir poursuivre la création ?",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_SUBTITLE_zero:
    "Vous êtes sur le point de créer le lot {{kitBatchName}} avec un panel de {{count}} cible. Veuillez vérifier les informations saisies avant de confirmer la création.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_SUBTITLE_one:
    "Vous êtes sur le point de créer le lot {{kitBatchName}} avec un panel de {{count}} cible. Veuillez vérifier les informations saisies avant de confirmer la création.",
  KIT_BATCHES_CREATION_CONFIRMATION_MODAL_SUBTITLE_other:
    "Vous êtes sur le point de créer le lot {{kitBatchName}} avec un panel de {{count}} cibles. Veuillez vérifier les informations saisies avant de confirmer la création.",
  KIT_BATCHES_EDITION_CONFIRMATION_MODAL_SUBMIT_BUTTON:
    "Confirmer la modification",
  KIT_BATCHES_CREATION_SUCCESS_TOAST_MESSAGE: "Lot créé avec succès",
  /* KIT BATCHES LIST*/
  BACK_TO_KIT_BATCHES_LIST: "Retour à la liste des lots",
  KIT_BATCHES_LIST_TITLE_DESCRIPTION: "Liste des lots",
  KIT_BATCHES_LIST_TITLE: "Liste des lots",
  KIT_BATCHES_LIST_ADD_NEW_KIT_BATCH: "Ajouter un lot",
  KIT_BATCHES_LIST_TABLE_HEADER_BADGE_zero: "{{count}} lot",
  KIT_BATCHES_LIST_TABLE_HEADER_BADGE_one: "{{count}} lot",
  KIT_BATCHES_LIST_TABLE_HEADER_BADGE_other: "{{count}} lots",
  KIT_BATCHES_LIST_TABLE_HEADER_FILTER_ANALYSIS_LABEL: "Analyse",
  KIT_BATCHES_LIST_TABLE_HEADER_FILTER_RUN_NO_OPTION_MESSAGE:
    "Aucune analyse trouvée",
  KIT_BATCHES_LIST_TABLE_HEADER_FILTER_DATE_LABEL: "Date de création",
  KIT_BATCHES_LIST_TABLE_FILTER_RESET_LABEL: "Réinitialiser les filtres",
  KIT_BATCHES_LIST_TABLE_COLUMN_BATCH_NUMBER: "Numéro de lot",
  KIT_BATCHES_LIST_TABLE_COLUMN_ANALYSIS: "Analyse",
  KIT_BATCHES_LIST_TABLE_COLUMN_TARGETS: "Nombre de cibles",
  KIT_BATCHES_LIST_TABLE_TARGETS_NUMBER_zero: "{{count}} cible",
  KIT_BATCHES_LIST_TABLE_TARGETS_NUMBER_one: "{{count}} cible",
  KIT_BATCHES_LIST_TABLE_TARGETS_NUMBER_other: "{{count}} cibles",
  KIT_BATCHES_LIST_TABLE_COLUMN_CREATION_DATE: "Crée le",
  KIT_BATCHES_LIST_TABLE_COLUMN_EXPIRATION_DATE: "Péremption",
  KIT_BATCHES_LIST_TABLE_COLUMN_CREATOR: "Crée par",
  KIT_BATCHES_LIST_TABLE_COLUMN_ACTIVITY: "Statut",
  KIT_BATCHES_LIST_ACTIVE: "Actif",
  KIT_BATCHES_LIST_INACTIVE: "Inactif",
  KIT_BATCHES_LIST_MENU_BUTTON_LABEL: "Actions pour le lot",
  KIT_BATCHES_LIST_MENU_ACTIVATE: "Activer",
  KIT_BATCHES_LIST_MENU_DEACTIVATE: "Archiver",
  KIT_BATCHES_LIST_MENU_UPDATE: "Modifier",
  KIT_BATCHES_LIST_MENU_DELETE: "Supprimer",
  KIT_BATCHES_LIST_ACTIVATE_SUCCESS_TOAST_MESSAGE: "Lot activé avec succès",
  KIT_BATCHES_LIST_ACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant l'activation du lot. Veuillez réessayer.",
  KIT_BATCHES_LIST_DEACTIVATE_SUCCESS_TOAST_MESSAGE:
    "Lot désactivé avec succès",
  KIT_BATCHES_LIST_DEACTIVATE_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la désactivation du lot. Veuillez réessayer.",
  /* KIT_BATCHES_LIST_DELETION_MODAL */
  KIT_BATCHES_LIST_DELETION_MODAL_TITLE:
    "Suppression du lot\n'{{kitBatchName}}'",
  KIT_BATCHES_LIST_DELETION_MODAL_SUBTITLE:
    "Êtes-vous sûr de vouloir supprimer le lot '{{kitBatchName}}' ?",
  KIT_BATCHES_LIST_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Supprimer le lot",
  KIT_BATCHES_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Lot supprimé avec succès",
  KIT_BATCHES_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la suppression du lot. Veuillez réessayer.",
  /* KIT BATCHES EDIT PAGE */
  KIT_BATCHES_EDITION_TITLE_DESCRIPTION: "Édition du lot {{kitBatchName}}",
  KIT_BATCHES_EDITION_FORM_SUBMIT_BUTTON_LABEL: "Modifier le lot",
  /* KIT BATCHES DETAILS PAGE */
  KIT_BATCHES_DETAILS_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Lot non trouvé. Retour à la liste des lots.",
  KIT_BATCHES_DETAILS_TITLE_DESCRIPTION: "Lot {{kitBatchName}}",
  KIT_BATCHES_DETAILS_INFORMATIONS_TITLE: "Informations",
  KIT_BATCHES_DETAILS_INFORMATIONS_SUBTITLE: "Informations principales du lot",
  KIT_BATCHES_DETAILS_BATCH_NUMBER_LABEL: "Numéro de lot",
  KIT_BATCHES_DETAILS_ANALYSIS_LABEL: "Analyse associée",
  KIT_BATCHES_DETAILS_EXPIRY_LABEL: "Date de péremption",
  KIT_BATCHES_DETAILS_CREATION_LABEL: "Création",
  KIT_BATCHES_DETAILS_CREATION_DATE_VALUE: "Le {{- date}}",
  KIT_BATCHES_DETAILS_CREATION_VALUE: "Le {{- date}}, par {{user}}",
  KIT_BATCHES_DETAILS_LAST_MODIFICATION_LABEL: "Dernière modification",
  KIT_BATCHES_DETAILS_LAST_MODIFICATION_DATE_VALUE: "Le {{- date}}",
  KIT_BATCHES_DETAILS_LAST_MODIFICATION_VALUE: "Le {{- date}}, par {{user}}",
  KIT_BATCHES_DETAILS_TARGETS_TITLE: "Cibles associées au lot",
  KIT_BATCHES_DETAILS_TARGETS_DESCRIPTION:
    "Pour chaque cibles, retrouvez la borne minimale et maximale d'acception pour la validation des contrôles de ce lot",
  KIT_BATCHES_DETAILS_NO_TARGET: "Aucune borne trouvée",
  KIT_BATCHES_DETAILS_THRESHOLDS_LOWER_THRESHOLD: "Borne min.",
  KIT_BATCHES_DETAILS_THRESHOLDS_LOWER_THRESHOLD_BADGE_LABEL:
    "Min. {{defined}}/{{expected}}",
  KIT_BATCHES_DETAILS_THRESHOLDS_UPPER_THRESHOLD: "Borne Max.",
  KIT_BATCHES_DETAILS_THRESHOLDS_UPPER_THRESHOLD_BADGE_LABEL:
    "Max. {{defined}}/{{expected}}",
  KIT_BATCHES_DETAILS_THRESHOLDS_NOT_DEFINED: "Non définie",
  KIT_BATCHES_DETAILS_EDIT_BUTTON: "Modifier le lot",
  /* LOGIN */
  LOGIN_TITLE_DESCRIPTION: "Connexion à Geeng",
  LOGIN_WELCOME_TITLE: "Heureux de vous revoir",
  LOGIN_WELCOME_SUBTITLE: "Entrez votre identifiant et\nvotre mot de passe",
  LOGIN_LANGUAGE_SELECTOR_LABEL: "Sélectionnez la langue de votre choix",
  LOGIN_FORM_ERROR_INVALID_PASSWORD_OR_IDENTIFIER:
    "Identifiant ou mot de passe incorrects",
  LOGIN_FORM_IDENTIFIER_LABEL: "Identifiant",
  LOGIN_FORM_IDENTIFIER_ERROR_REQUIRED: "L'identifiant est requis",
  LOGIN_FORM_PASSWORD_LABEL: "Mot de passe",
  LOGIN_FORM_PASSWORD_ERROR_REQUIRED: "Le mot de passe est requis",
  LOGIN_FORM_SUBMIT_BUTTON: "Connexion",
  LOGIN_FORGOTTEN_PASSWORD_BUTTON: "Mot de passe oublié ?",
  /* LOGO */
  LOGO_APP_TITLE: "Geeng",
  LOGO_CUSTOMER: "CHU de Rennes",
  title: "Bienvenue dans As We Care",
  /* LOGOUT */
  LOGOUT_TOKEN_EXPIRED_TOAST_MESSAGE:
    "Votre session a expiré, reconnectez-vous.",
  LOGOUT_SUCCESSFUL_TOAST_MESSAGE: "Vous êtes désormais déconnecté",
  /* MAIN APP SIDEBAR */
  MAIN_APP_SIDEBAR_ITEMS_DASHBOARD: "Dashboard",
  MAIN_APP_SIDEBAR_ITEMS_APPLICATION_DPCR: "DPCR",
  MAIN_APP_SIDEBAR_ITEMS_NOTIFICATIONS: "Notifications",
  /* MATH FIELD */
  MATH_FIELD_COPIED: "Formule copiée",
  MATH_FIELD_MODAL_VARIABLES_TITLE: "Variables",
  MATH_FIELD_MODAL_VARIABLES_SEARCH_LABEL: "Chercher un nom de variable",
  MATH_FIELD_VARIABLES_TABS_LABEL:
    "Choisissez entre les variables brutes directement issues du fichier de résultat et les variables calculées définies par l'utilisateur pendant le parcours de création de l'analyse",
  MATH_FIELD_RAW_VARIABLES: "Variables brutes",
  MATH_FIELD_CALCULATED_VARIABLES: "Variables calculées",
  MATH_FIELD_NO_VARIABLE_FOUND: "Aucune variable trouvée",
  MATH_FIELD_PLACEHOLDER: "\\mathrm{Entrez\\ ou\\ collez\\ votre\\ formule}",
  /* MATRICES */
  MATRICES_CF_DNA: "cf-DNA",
  MATRICES_F_DNA: "f-DNA",
  /* QUALITY CONTROL RESULT */
  QUALITY_CONTROL_RESULT_PASS_LABEL: "Conforme",
  QUALITY_CONTROL_RESULT_FAIL_LABEL: "Non Conforme",
  QUALITY_CONTROL_RESULT_WARNING_LABEL: "À surveiller",
  /* RUNS */
  /* RUNS STATUS */
  RUN_STATUS_VALIDATED: "Validé",
  RUN_STATUS_READY: "Disponible",
  RUN_STATUS_WAITING: "En attente",
  RUN_STATUS_FAILED: "Erreur",
  RUN_STATUS_PROCESSING: "En traitement",
  RUN_STATUS_QUEUED: "En file d'attente",
  /* RUNS QC VALIDTION STATUS */
  RUN_QUALITY_CONTROL_VALIDATION_DONE:
    "Validation des Contrôles Qualité effectuée",
  RUN_QUALITY_CONTROL_VALIDATION_TODO:
    "Validation des Contrôles Qualité à faire",
  /* RUNS CONTROLS TYPE */
  TYPE_BLANK_CONTROL: "Contrôle Blanc",
  TYPE_BLANK_CONTROL_SHORT: "TB",
  TYPE_NEG_CONTROL: "Contrôle TN",
  TYPE_NEG_CONTROL_SHORT: "TN",
  TYPE_POS_CONTROL: "Contrôle TPC",
  TYPE_POS_CONTROL_SHORT: "TP",
  QC_CONTROLS_TOOLTIP:
    "Résultat pour le contrôle qualité {{type}} : {{result}}",
  QC_CONTROLS_TOOLTIP_PASS: "conforme",
  QC_CONTROLS_TOOLTIP_FAIL: "non conforme",
  QC_CONTROLS_TOOLTIP_UNKNOWN: "non connu",
  QC_CONTROLS_TOOLTIP_ERROR:
    "Une erreur est survenue pendant le calcul du résultat de ce contrôle qualité",
  QC_CONTROLS_TOOLTIP_TRUE_zero: "conforme, {{count}} partition positive",
  QC_CONTROLS_TOOLTIP_TRUE_one: "conforme, {{count}} partition positive",
  QC_CONTROLS_TOOLTIP_TRUE_other: "conforme, {{count}} partitions positives",
  QC_CONTROLS_TOOLTIP_FALSE_zero: "non conforme, {{count}} partition positive",
  QC_CONTROLS_TOOLTIP_FALSE_one: "non conforme, {{count}} partition positive",
  QC_CONTROLS_TOOLTIP_FALSE_other:
    "non conforme, {{count}} partitions positives",
  /* RUNS LIST*/
  RUNS_LIST_TITLE_DESCRIPTION: "Liste des Runs",
  RUNS_LIST_TITLE: "Liste des Runs",
  RUNS_LIST_ADD_NEW_SERIE: "Ajouter une série",
  RUNS_LIST_TABLE_HEADER_TITLE: "Tous nos runs",
  RUNS_LIST_TABLE_HEADER_BADGE_zero: "{{count}} Série",
  RUNS_LIST_TABLE_HEADER_BADGE_one: "{{count}} Série",
  RUNS_LIST_TABLE_HEADER_BADGE_other: "{{count}} Séries",
  RUNS_LIST_TABLE_HEADER_FILTER_SERIES_LABEL: "Série",
  RUNS_LIST_TABLE_HEADER_FILTER_SERIES_NO_OPTION_MESSAGE:
    "Aucune série trouvée",
  RUNS_LIST_TABLE_HEADER_FILTER_RUN_LABEL: "Run",
  RUNS_LIST_TABLE_HEADER_FILTER_RUN_NO_OPTION_MESSAGE: "Aucun run trouvé",
  RUNS_LIST_TABLE_HEADER_FILTER_ANALYSIS_LABEL: "Analyse",
  RUNS_LIST_TABLE_HEADER_FILTER_ANALYSIS_NO_OPTION_MESSAGE:
    "Aucune analyse trouvée",
  RUNS_LIST_TABLE_HEADER_FILTER_STATUS_LABEL: "Statut",
  RUNS_LIST_TABLE_HEADER_FILTER_DATE_LABEL: "Date",
  RUNS_LIST_TABLE_FILTER_RESET_LABEL: "Réinitialiser les filtres",
  RUNS_LIST_TABLE_COLUMN_SERIE: "Séries",
  RUNS_LIST_TABLE_COLUMN_SERIE_MISSING_DATA_TOOLTIP_zero:
    "Cette série attend toujours {{count}} résultat d'analyse d'échantillon par rapport aux données des samplesheets",
  RUNS_LIST_TABLE_COLUMN_SERIE_MISSING_DATA_TOOLTIP_one:
    "Cette série attend toujours {{count}} résultat d'analyse d'échantillon par rapport aux données des samplesheets",
  RUNS_LIST_TABLE_COLUMN_SERIE_MISSING_DATA_TOOLTIP_other:
    "Cette série attend toujours {{count}} résultats d'analyse d'échantillons par rapport aux données des samplesheets",
  RUNS_LIST_TABLE_COLUMN_SERIE_TOOLTIP: "Nombre d'échantillons traités",
  RUNS_LIST_TABLE_COLUMN_RUNS: "Runs",
  RUNS_LIST_TABLE_COLUMN_RUNS_TOOLTIP: "Nombre d'échantillons du run",
  RUNS_LIST_TABLE_COLUMN_ANALYSIS: "Analyse",
  RUNS_LIST_TABLE_COLUMN_STATE: "État",
  RUNS_LIST_TABLE_COLUMN_DATE: "Date",
  RUNS_LIST_TABLE_COLUMN_EDIT_RUN_LINK:
    "Voir plus de détails à propos du run {{runId}}",
  /* SAMPLE DETAILS */
  SAMPLE_DETAILS_SAMPLE_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Échantillon non trouvé. Retour à la list des échantillons de la série.",
  SAMPLE_DETAILS_BREADCRUMBS_BACK_LABEL: "Retour à la liste",
  SAMPLE_DETAILS_BREADCRUMBS_RIGHT_COMPONENT_LABEL_NEXT_SAMPLE:
    "Échantillon suivant",
  SAMPLE_DETAILS_BREADCRUMBS_RIGHT_COMPONENT_LABEL_BACK_TO_LIST:
    "Retour à la liste",
  SAMPLE_DETAILS_TITLE_DESCRIPTION: "Détails de l'échantillon {{sampleId}}",
  SAMPLE_DETAILS_TITLE: "Échantillon",
  SAMPLE_DETAILS_TITLE_STATUS: "Statut :",
  SAMPLE_DETAILS_TITLE_COMMENTS_zero: "{{count}} commentaire",
  SAMPLE_DETAILS_TITLE_COMMENTS_one: "{{count}} commentaire",
  SAMPLE_DETAILS_TITLE_COMMENTS_other: "{{count}} commentaires",
  SAMPLE_DETAILS_HEADER_PATHOLOGY: "Pathologie",
  SAMPLE_DETAILS_HEADER_SAMPLE_TYPE: "S. Type",
  SAMPLE_DETAILS_HEADER_CELL_QUANTITY: "Q. Cell.",
  SAMPLE_DETAILS_HEADER_ANALYSES: "Analyses",
  SAMPLE_DETAILS_ACTION_BUTTON_LABEL: "Actions",
  SAMPLE_DETAILS_ACTION_MENU_EXPORT: "Export",
  SAMPLE_DETAILS_ACTION_MENU_EXPORT_DISABLED_TOOLTIP:
    "Sélectionnez au moins une cible pour l'export",
  SAMPLE_DETAILS_EXPORT_MODAL_TITLE: "Export des données",
  SAMPLE_DETAILS_EXPORT_MODAL_INFORMATIONS: "Informations",
  SAMPLE_DETAILS_EXPORT_MODAL_INFORMATIONS_SAMPLE_ID: "ID Échantillon",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_zero: "{{count}} cible",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_one: "{{count}} cible",
  SAMPLE_DETAILS_EXPORT_MODAL_ANALYSIS_TARGETS_COUNT_other: "{{count}} cibles",
  SAMPLE_DETAILS_EXPORT_MODAL_SUBMIT_LABEL: "Exporter",
  SAMPLE_DETAILS_EXPORT_SUCCESS_TOAST_MESSAGE: "Export effectué avec succès",
  SAMPLE_DETAILS_EXPORT_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant l'export des données. Veuillez réessayer.",
  SAMPLE_DETAILS_TABLE_COLUMN_ANALYSIS: "Analyse",
  SAMPLE_DETAILS_TABLE_COLUMN_ANALYSIS_STATUS: "Statut",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL: "QC",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL_VALIDATION_PASS: "Passe",
  SAMPLE_DETAILS_TABLE_COLUMN_QUALITY_CONTROL_VALIDATION_NOT_PASS:
    "Ne passe pas",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_CHAMBER: "Puits (part.)",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_SAMPLE: "Échantillon (part.)",
  SAMPLE_DETAILS_TABLE_QUALITY_CONTROL_CELL_DNA: "ADN (ng)",
  SAMPLE_DETAILS_TABLE_COLUMN_TARGET: "Cible",
  SAMPLE_DETAILS_TABLE_COLUMN_BLANK_CONTROL: "TB",
  SAMPLE_DETAILS_TABLE_COLUMN_NEG_CONTROL: "TN",
  SAMPLE_DETAILS_TABLE_COLUMN_POS_CONTROL: "TP",
  SAMPLE_DETAILS_TABLE_COLUMN_PARTITIONS: "PART.",
  SAMPLE_DETAILS_TABLE_COLUMN_VAF: "VAF",
  SAMPLE_DETAILS_TABLE_COLUMN_SENSITIVITY: "Max Sens.",
  SAMPLE_DETAILS_TABLE_COLUMN_UNCERTAINTY: "Incert.",
  SAMPLE_DETAILS_TABLE_COLUMN_INTERPRETATION: "Interprétation",
  SAMPLE_DETAILS_TABLE_COLUMN_FLAGGED_TOOLTIP_DISABLED:
    "Impossible de déclarer en variant d'intérêt une cible non mutée",
  SAMPLE_DETAILS_TABLE_VALIDATE_RUN_RESULT: "Valider",
  SAMPLE_DETAILS_VALIDATED: "Validé",
  SAMPLE_DETAILS_UPDATE_RUN_SUCCESS_TOAST_MESSAGE:
    "Information mise à jour avec succès",
  SAMPLE_DETAILS_UPDATE_RUN_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la mise à jour. Veuillez réessayer.",
  SAMPLE_DETAILS_ADD_COMMENT_SUCCESS_TOAST_MESSAGE:
    "Commentaire ajouté avec succès",
  SAMPLE_DETAILS_ADD_COMMENT_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant l'ajout du commentaire. Veuillez réessayer.",
  /* SAMPLE INTERPRETATION */
  UNKNOWN: "Non interprété",
  NON_CONTRIBUTIVE: "Non contributif",
  UNMUTATED: "Non muté",
  MUTATED: "Muté",
  /* SAMPLE POSITIVITY */
  SAMPLE_POSITIVITY_CANDIDATE: "Candidat",
  SAMPLE_POSITIVITY_POSITIVE: "Positif",
  /* SAMPLE PRIORITY */
  SAMPLE_PRIORITY_LOW: "Basse",
  SAMPLE_PRIORITY_NORMAL: "Routine",
  SAMPLE_PRIORITY_HIGH: "Urgent",
  /* SAMPLE STATUS */
  SAMPLE_STATUS_READY: "À faire",
  SAMPLE_STATUS_PROCESSING: "En cours",
  SAMPLE_STATUS_VALIDATED: "Validé",
  SAMPLE_STATUS_ERROR: "Erreur",
  /* SAMPLE TYPE */
  SAMPLE_TYPE_F_DNA: "free DNA",
  SAMPLE_TYPE_F_DNA_SHORT: "F",
  SAMPLE_TYPE_CF_DNA: "cell-free DNA",
  SAMPLE_TYPE_CF_DNA_SHORT: "CF",
  /* SAMPLESHEETS LIST */
  SAMPLESHEETS_LIST_CARD_MENU_BUTTON_LABEL: "Actions pour la samplesheet",
  SAMPLESHEETS_LIST_CARD_MENU_DELETE: "Supprimer",
  SAMPLESHEETS_LIST_CARD_MENU_DOWNLOAD: "Télécharger",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_TITLE: "Suppression de la samplesheet",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Êtes-vous sûr de vouloir supprimer la samplesheet\n{{- samplesheetName}} ?",
  SAMPLESHEETS_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON:
    "Supprimer la samplesheet",
  SAMPLESHEETS_LIST_DELETION_SUCCESS_TOAST_MESSAGE:
    "Samplesheet supprimée avec succès",
  SAMPLESHEETS_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la suppression de la samplesheet. Veuillez réessayer.",
  /* SERIE CREATION */
  SERIES_CREATION_TITLE_DESCRIPTION: "Création d'une série",
  SERIES_CREATION_TITLE: "Création d'une série",
  SERIES_CREATION_SUBTITLE:
    "Attribuez un nom unique à cette série pour faciliter son identification. Vous pourrez ensuite charger les fichiers de samplesheets la concernant puis les fichiers de résultats de Runs.",
  SERIES_CREATION_DESCRIPTION: "Description",
  SERIES_CREATION_FORM_INFORMATIONS_TITLE: "Informations",
  SERIES_CREATION_FORM_AUTHOR: "Auteur",
  SERIES_CREATION_FORM_DATE: "Date",
  SERIES_CREATION_FORM_IDENTIFIER_LABEL: "Nom de la série",
  SERIES_CREATION_FORM_IDENTIFIER_PLACEHOLDER: "Saisissez le nom de la série",
  SERIES_CREATION_FORM_SUBMIT: "Créer la série",
  SERIES_CREATION_FORM_SUCCESS_TOAST: "Série créée avec succès",
  /* SERIE DETAILS*/
  SERIES_DETAILS_TITLE: "Série",
  SERIES_DETAILS_TITLE_DESCRIPTION: "Détails de la série '{{serieName}}'",
  SERIES_DETAILS_SERIE_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Série non trouvée. Retour à la liste des séries.",
  SERIES_DETAILS_RUN_NOT_FOUND_ERROR_TOAST_MESSAGE:
    "Run non trouvé pour la série. Retour à la page détails de la série.",
  /* SERIE DETAILS NO RESULTS*/
  SERIES_DETAILS_NO_RESULTS_TITLE: "Détails de la série",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_TITLE: "Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_IMPORT_BUTTON_LABEL:
    "Ajouter des Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_zero:
    "Samplesheet",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_one:
    "Samplesheet",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_SAMPLESHEETS_NB_other:
    "Samplesheets",
  SERIES_DETAILS_NO_RESULTS_SAMPLESHEETS_CARD_ADD_DESCRIPTION:
    "Aucune Samplesheet transmise pour cette série.",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_TITLE: "Fichiers de résultats",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_RESULTS_NB: "Fichier de résultats",
  SERIES_DETAILS_NO_RESULTS_RESULTS_CARD_ADD_DESCRIPTION:
    "Aucun fichier de résultats transmis pour cette série.",
  SERIES_DETAILS_NO_RESULTS_RUNS_RESULTS_IMPORT_BUTTON_LABEL:
    "Ajouter des résultats",
  /* SERIE DETAILS RUN IN TREATMENT */
  SERIES_DETAILS_RUN_TREATMENT_TITLE:
    "Fichier de résultat en cours de traitement",
  /* SERIE DETAILS CONTROL VALIDATION */
  SERIES_DETAILS_CONTROL_VALIDATION_TITLE: "Validation des contrôles",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_LABEL: "Runs de la série",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_zero: "Échantillon",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_one: "Échantillon",
  SERIES_DETAILS_CONTROL_VALIDATION_RUNS_TABS_SAMPLES_LABEL_other:
    "Échantillons",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TITLE: "Analyse, Kit & Puce",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_ANALYSIS_COLUMN_TITLE:
    "Analyse",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_SUPPLIER_COLUMN_TITLE:
    "Fournisseur",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_CHIP_COLUMN_TITLE:
    "Puce",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_KIT_COLUMN_TITLE: "Kit",
  SERIES_DETAILS_CONTROL_VALIDATION_ANALYSIS_CARD_TABLE_TARGETS_COLUMN_TITLE:
    "Cibles",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_zero: "{{count}} Puce",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_one: "{{count}} Puce",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_other:
    "{{count}} Puces",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_INDEX:
    "Puce n°{{chipIndex}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_VALIDATION_TOOLTIP:
    "Validez ou non les Contrôles Qualité pour la puce n°{{chipIndex}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_CHIP_TABLE_COLUMN_HEADER_ID:
    "ID",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_zero:
    "Échantillon {{sample}} : {{count}} partition pour cet échantillon",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_one:
    "Échantillon {{sample}} : {{count}} partition pour cet échantillon",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART1_other:
    "Échantillon {{sample}} : {{count}} partitions pour cet échantillon",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_zero:
    "{{count}} partition attendue pour satisfaire la règle de contrôle qualité de la puce",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_one:
    "{{count}} partition attendue pour satisfaire la règle de contrôle qualité de la puce",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_SAMPLE_PARTITIONS_NUMBER_TOOLTIP_PART2_other:
    "{{count}} partitions attendues pour satisfaire la règle de contrôle qualité de la puce",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_VALIDATION_SUCCESS_TOAST_MESSAGE:
    "Contrôle qualité de la puce mise à jour avec succès",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_VALIDATION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la mise à jour du contrôle qualité de la puce. Veuillez réessayer.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_UPDATE_SAMPLE_RESULT_SUCCESS_TOAST_MESSAGE:
    "Résultat du contrôle qualité lié à l'échantillon {{sampleId}} mis à jour avec succès",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_GLOBAL_CARD_UPDATE_SAMPLE_RESULT_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la mise à jour du résultat du contrôle qualité lié à l'échantillon {{sampleId}}. Veuillez réessayer.",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TITLE:
    "Contrôle Qualité des Témoins",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_zero:
    "{{count}} Témoin",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_one:
    "{{count}} Témoin",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_CHIP_other:
    "{{count}} Témoins",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_TYPE_COLUMN_TITLE:
    "Type de contrôle",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_BATCH_NUMBER:
    "N° Lot : {{batchNumber}}",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_ID_COLUMN_TITLE:
    "ID",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_TABLE_CONTROL_PARTITIONS_NB_COLUMN_TITLE:
    "Nb partitions",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_UPDATE_SAMPLE_RESULT_SUCCESS_TOAST_MESSAGE:
    "Résultat du contrôle qualité du témoin {{sampleId}} mis à jour avec succès",
  SERIES_DETAILS_CONTROL_VALIDATION_QC_CONTROLS_CARD_UPDATE_SAMPLE_RESULT_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la mise à jour du résultat du contrôle qualité du témoin {{sampleId}}. Veuillez réessayer.",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON:
    "Valider les contrôles du run",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFOS_one:
    "Le statut suivant n'a pas été défini :",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFOS_other:
    "Les statuts suivants n'ont pas été définis :",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFO_CHIP:
    "Puce {{chipId}}",
  SERIES_DETAILS_CONTROL_VALIDATION_SUBMIT_BUTTON_TOOLTIP_INFO_CONTROL:
    "Contrôle {{type}} (N° Lot {{batchNumber}})",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_TITLE:
    "Validation des contrôles qualité du run",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUBTITLE:
    "Vous vous apprêtez à valider les contrôles qualité du run. Vous pouvez laisser un commentaire pour apporter des précisions.",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUMMARY_INFORMATIONS:
    "Informations",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_ANALYSIS: "Analyse",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_zero:
    "Nombre de puce",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_one:
    "Nombre de puce",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CHIPS_other:
    "Nombre de puces",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_zero:
    "Nombre de témoin",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_one:
    "Nombre de témoin",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_CONTROLS_other:
    "Nombre de témoins",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_VALIDATION_DATE:
    "Date de validation",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_OPERATOR: "Par",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_COMMENTS: "Commentaires",
  SERIES_DETAILS_CONTROL_VALIDATION_CONFIRMATION_MODAL_SUBMIT:
    "Valider les contrôles du run",
  SERIES_DETAILS_CONTROL_VALIDATED:
    "Contrôles qualité validés le {{- date}} par {{by}}",
  SERIES_DETAILS_CONTROL_VALIDATED_ANONYMOUS:
    "Contrôles qualité validés le {{- date}}",
  SERIES_DETAILS_CONTROL_VALIDATION_SUCCESS_TOAST_MESSAGE:
    "Validation des contrôles qualité du run effectué avec succès",
  SERIES_DETAILS_CONTROL_VALIDATION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la validation des contrôles qualité du run. Veuillez réessayer.",
  /* SERIES DETAIL SAMPLES LIST */
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE: "Tous les échantillons",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_zero: "{{count}} Échantillon",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_one: "{{count}} Échantillon",
  SERIES_DETAILS_SAMPLES_LIST_SUBTITLE_COUNT_other: "{{count}} Échantillons",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_SAMPLE_ID_LABEL: "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_SAMPLE_ID_NO_OPTION_MESSAGE:
    "Aucun Sample ID trouvé",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_PATHOLOGY_LABEL: "Pathologie",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_PATHOLOGY_NO_OPTION_MESSAGE:
    "Aucune pathologie trouvée",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_STATUS_LABEL: "Statut",
  SERIES_DETAILS_SAMPLES_LIST_FILTER_RESET_LABEL: "Réinitialiser les filtres",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_ID: "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_TITLE: "Commentaires",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS:
    "Informations échantillon",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_SAMPLE_ID:
    "Sample ID",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_PATHOLOGY:
    "Pathologie",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_MAIN_INFORMATIONS_ANALYSES:
    "Analyses",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_COMMENTS:
    "Les commentaires",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_ADD_LABEL:
    "Ajouter un commentaire",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_ADD_DESCRIPTION:
    "Cet échantillon n’a pas encore de commentaire, souhaitez vous en ajouter un ?",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_ADD_COMMENT_TEXTAREA_PLACEHOLDER:
    "Votre commentaire",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_ADD_COMMENT_SUBMIT:
    "Ajouter commentaire",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COMMENT_MODAL_SAMPLE_FOLLOW_LINK:
    "Consulter",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_PRIORITY: "Priorité",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_PATHOLOGY: "Pathologie",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_SAMPLE_TYPE: "S. Type",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_CELL_QUANTITY: "Q. Cellulaire",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_ANALYSES: "Analyses",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_ANALYSES_TOOLTIP:
    "Interprétation pour l'analyse {{analysisName}} : {{interpretation}}",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_POSITIVITY: "Positivité",
  SERIES_DETAILS_SAMPLES_LIST_TABLE_COLUMN_STATUS: "Statut",
  /* SERIES INCOMPLETE LIST */
  SERIES_INCOMPLETE_LIST_TITLE:
    "Upload de résultats : liste des séries incomplètes",
  SERIES_INCOMPLETE_LIST_DESCRIPTION:
    "Sélectionner une série incomplète dans la liste ci-dessous pour y ajouter des fichiers de résultats",
  SERIES_INCOMPLETE_LIST_TABLE_HEADER_TITLE: "Toutes nos séries incomplètes",
  /* SERIES LIST */
  BACK_TO_SERIES_LIST: "Retour à la liste des séries",
  SERIES_LIST_TITLE_DESCRIPTION: "Liste des séries",
  SERIES_LIST_TITLE: "Liste des séries",
  SERIES_LIST_TABLE_HEADER_TITLE: "Toutes nos séries",
  SERIES_LIST_TABLE_HEADER_BADGE_zero: "{{count}} série",
  SERIES_LIST_TABLE_HEADER_BADGE_one: "{{count}} série",
  SERIES_LIST_TABLE_HEADER_BADGE_other: "{{count}} séries",
  SERIES_LIST_TABLE_HEADER_FILTER_BUTTON: "Filtrer",
  SERIES_LIST_TABLE_HEADER_FILTER_DATE_LABEL: "Filtre par date",
  SERIES_LIST_TABLE_HEADER_FILTER_DATE_PLACEHOLDER: "Sélectionner une date",
  SERIES_LIST_CARD_DATE_LABEL: "Date",
  SERIES_LIST_CARD_EXPECTED_SAMPLES_LABEL: "Samples attendus",
  SERIES_LIST_CARD_TREATED_SAMPLES_LABEL: "Samples traités",
  SERIES_LIST_CARD_MENU_BUTTON_LABEL: "Actions pour la série",
  SERIES_LIST_CARD_MENU_ADD_NEW_SAMPLESHEET: "Ajouter samplesheet",
  SERIES_LIST_CARD_SEE_DETAILS_LINK: "Voir en détails",
  SERIES_LIST_CARD_SELECT_SERIES: "Sélectionner cette série",
  SERIES_LIST_CARD_DELETION_MODAL_TITLE:
    "Suppression de la série\n'{{serieName}}'",
  SERIES_LIST_CARD_DELETION_MODAL_SUBTITLE:
    "Êtes-vous sûr de vouloir supprimer la série '{{serieName}}' ?",
  SERIES_LIST_CARD_DELETION_MODAL_FORM_SUBMIT_BUTTON: "Supprimer la série",
  SERIES_LIST_DELETION_SUCCESS_TOAST_MESSAGE: "Série supprimée avec succès",
  SERIES_LIST_DELETION_ERROR_TOAST_MESSAGE:
    "Une erreur s'est produite pendant la suppression de la série. Veuillez réessayer.",
  /* SERIES RUNS RESULTS IMPORT */
  SERIES_RUNS_RESULTS_IMPORT_TITLE: "Ajouter des fichiers de résultats",
  SERIES_RUNS_RESULTS_IMPORT_TITLE_BADGE: "Série {{serieName}}",
  SERIES_RUNS_RESULTS_IMPORT_TITLE_MISSING_DATA:
    "Impossible de récupérer les données associées à la série sélectionnée.",
  SERIES_RUNS_RESULTS_DESCRIPTION: "Description",
  SERIES_RUNS_RESULTS_IMPORT_SUBTITLE:
    "Déposer ou sélectionner les fichiers de résultats de cette série. Pour chaque fichier, vous pouvez le renommer puis lui associer le numéro de lot utilisé pour les contrôles et l'analyse utilisée.",
  SERIES_RUNS_RESULTS_IMPORT_TABS_LABEL:
    "Informations et résumé de l'import de résultats",
  SERIES_RUNS_RESULTS_IMPORT_TABS_INFORMATIONS: "Informations",
  SERIES_RUNS_RESULTS_IMPORT_TABS_SUMMARY: "Résumé",
  SERIES_RUNS_RESULTS_IMPORT_INFORMATIONS: "Renseigner les informations",
  SERIES_RUNS_RESULTS_IMPORT_FORM_IMPORT_ERROR_MESSAGE: "Échec de l'import",
  SERIES_RUNS_RESULTS_IMPORT_FORM_IMPORT_SUCCESSFUL: "Fichier importé",
  SERIES_RUNS_RESULTS_IMPORT_FORM_NAME_LABEL: "Nom du run",
  SERIES_RUNS_RESULTS_IMPORT_FORM_ANALYSIS_LABEL: "Analyse",
  SERIES_RUNS_RESULTS_IMPORT_FORM_ANALYSIS_PLACEHOLDER: "Analyse",
  SERIES_RUNS_RESULTS_IMPORT_FORM_ANALYSIS_NO_OPTION_MESSAGE:
    "Aucune analyse trouvée",
  SERIES_RUNS_RESULTS_IMPORT_FORM_KIT_BATCH_LABEL: "Numéro de lot",
  SERIES_RUNS_RESULTS_IMPORT_FORM_KIT_BATCH_PLACEHOLDER: "Numéro de lot",
  SERIES_RUNS_RESULTS_IMPORT_FORM_KIT_BATCH_NO_OPTION_MESSAGE:
    "Aucun numéro de lot trouvé",
  SERIES_RUNS_RESULTS_IMPORT_FORM_SUBMIT_BUTTON: "Importer",
  SERIES_RUNS_RESULTS_IMPORT_FORM_SEE_SUMMARY: "Voir le résumé",
  SERIES_RUNS_RESULTS_OPEN_SERIES: "Voir la série",
  /* SERIES SAMPLESHEETS IMPORT */
  SERIES_SAMPLESHEETS_IMPORT_TITLE: "Ajouter des Samplesheets",
  SERIES_SAMPLESHEETS_IMPORT_TITLE_BADGE: "Série {{serieName}}",
  SERIES_SAMPLESHEETS_IMPORT_TITLE_ADMIN_RIGHTS_NEEDED:
    "Vous n'avez pas les droits pour effectuer récupérer les noms des utilisateurs.",
  SERIES_SAMPLESHEETS_IMPORT_TITLE_MISSING_DATA:
    "Impossible de récupérer les données associées à la série sélectionnée.",
  SERIES_SAMPLESHEETS_DESCRIPTION: "Description",
  SERIES_SAMPLESHEETS_IMPORT_SUBTITLE:
    "Déposer ou sélectionner les fichiers de samplesheet en les associant à un ou plusieurs opérateurs.",
  SERIES_SAMPLESHEETS_IMPORT_TABS_LABEL:
    "Informations et résumé de l'import de samplesheets",
  SERIES_SAMPLESHEETS_IMPORT_TABS_INFORMATIONS: "Informations",
  SERIES_SAMPLESHEETS_IMPORT_TABS_SUMMARY: "Résumé",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_LABEL: "Opérateur(s)",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_NO_OPTION:
    "Aucun opérateur trouvé",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_OPERATOR_PLACEHOLDER:
    "Ajouter des opérateurs",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_SUPPORTED_FILES_LABEL:
    "Les fichiers supportés sont seulement : .xlsx et .xls",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_SELECT_FILES_BUTTON_LABEL:
    "Cliquer pour sélectionner",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_LABEL: "Fichiers de samplesheets",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_INNER_TEXT:
    "ou déposer directement un fichier",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_INNER_SUBTEXT:
    "Formats supportés : {{formats}}",
  SERIES_SAMPLESHEETS_IMPORT_DROP_AREA_INNER_BUTTON: "Parcourir vos fichiers",
  SERIES_SAMPLESHEETS_IMPORT_SELECT_OPERATORS: "Sélectionner des opérateurs",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUBMIT_BUTTON: "Importer",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SEE_SUMMARY: "Voir le résumé",
  SERIES_SAMPLESHEETS_OPEN_UPLOAD_RESULTS_BUTTON: "Déposer les résultats",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_IMPORT_SUCCESSFUL:
    "Fichier importé",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUCCESS_TOAST_one:
    "Import du fichier de samplesheet effectué avec succès",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SUCCESS_TOAST_other:
    "Import des {{count}} fichiers de samplesheet effectué avec succès",
  SERIES_SAMPLESHEETS_IMPORT_FORM_SAMPLESHEET_IMPORT_ERROR_MESSAGE:
    "Échec de l'import",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_NB_SAMPLES_LABEL: "Nombre d'Échantillons",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_NB_SAMPLES_VALUE_zero:
    "{{count}} Échantillon",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_NB_SAMPLES_VALUE_one:
    "{{count}} Échantillon",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_NB_SAMPLES_VALUE_other:
    "{{count}} Échantillons",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_ANALYSES: "Analyses",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_ANALYSES_LABEL: "Nombre d'analyses",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_ANALYSES_VALUE_zero: "{{count}} Analyse",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_ANALYSES_VALUE_one: "{{count}} Analyse",
  SERIES_SAMPLESHEETS_IMPORT_SUMMARY_ANALYSES_VALUE_other: "{{count}} Analyse",
  /* SOFTWARES LITS */
  SOFTWARES_LIST_TITLE_DESCRIPTION: "Liste des logiciels",
  SOFTWARES_LIST_TITLE: "Liste des logiciels",
  SOFTWARES_LIST_SUBTITLE: "Tous les logiciels",
  SOFTWARES_LIST_SEARCH_SOFTWARE_LABEL: "Rechercher un logiciel",
  SOFTWARES_LIST_COUNT_LABEL_zero: "{{count}} logiciel",
  SOFTWARES_LIST_COUNT_LABEL_one: "{{count}} logiciel",
  SOFTWARES_LIST_COUNT_LABEL_other: "{{count}} logiciels",
  SOFTWARES_LIST_CONTACT_LABEL:
    "Vous souhaitez intégrer un nouveau logiciel dans l'application afin d'exploiter de nouveaux fichiers de résultats ? Prenez contact avec l'équipe Geeng",
  SOFTWARES_LIST_CONTACT_BUTTON_LABEL: "Contact",
  SOFTWARES_LIST_CARD_IS_ACTIVE: "Actif",
  /* WTNESSES */
  POSITIVE_CONTROL_zero: "témoin positif",
  POSITIVE_CONTROL_one: "témoin positif",
  POSITIVE_CONTROL_other: "témoins positifs",
  NEGATIVE_CONTROL_zero: "témoin négatif",
  NEGATIVE_CONTROL_one: "témoin négatif",
  NEGATIVE_CONTROL_other: "témoins négatifs",
  BLANK_CONTROL_zero: "témoin blanc",
  BLANK_CONTROL_one: "témoin blanc",
  BLANK_CONTROL_other: "témoins blancs",
}
