export default function Icon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="22"
      viewBox="0 0 80 22"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_16_2618)">
        <path
          d="M3.18787 20.1744V21.4582H2.70582V21.1335C2.60336 21.3394 2.33966 21.5017 1.9735 21.5017C1.33021 21.5017 0.821289 21.0013 0.821289 20.3049C0.821289 19.6086 1.33021 19.0948 2.02893 19.0948C2.60168 19.0948 3.0367 19.4396 3.16435 19.9267H2.55801C2.46563 19.7409 2.27583 19.6321 2.02893 19.6321C1.64262 19.6321 1.39236 19.9032 1.39236 20.3116C1.39236 20.6832 1.59895 20.9812 2.01549 20.9812C2.27248 20.9812 2.49083 20.869 2.58488 20.6464H1.89288V20.1761H3.18787V20.1744Z"
          fill="#1E1E1C"
        />
        <path
          d="M9.10501 19.6724H8.06701V20.0172H9.01263V20.5545H8.06701V20.9194H9.10501V21.4567H7.50098V19.1351H9.10501V19.6724Z"
          fill="#1E1E1C"
        />
        <path
          d="M14.8158 20.598L14.7722 20.1076V19.1351H15.3382V21.4567H14.6966L13.8215 19.9938L13.8685 20.4842V21.4567H13.3025V19.1351H13.9458L14.8175 20.598H14.8158Z"
          fill="#1E1E1C"
        />
        <path
          d="M21.2554 19.6724H20.2174V20.0172H21.163V20.5545H20.2174V20.9194H21.2554V21.4567H19.6514V19.1351H21.2554V19.6724Z"
          fill="#1E1E1C"
        />
        <path
          d="M27.1525 19.6724H26.5159V21.4567H25.9499V19.6724H25.3184V19.1351H27.1525V19.6724Z"
          fill="#1E1E1C"
        />
        <path
          d="M31.3315 19.1351H31.8976V21.4567H31.3315V19.1351Z"
          fill="#1E1E1C"
        />
        <path
          d="M36.1418 20.2966C36.1418 19.6136 36.6508 19.0931 37.3495 19.0931C37.9222 19.0931 38.3573 19.4513 38.4849 19.9551H37.8786C37.7862 19.7526 37.5964 19.6304 37.3495 19.6304C36.9632 19.6304 36.7129 19.9082 36.7129 20.2966C36.7129 20.6849 36.9632 20.9628 37.3495 20.9628C37.5964 20.9628 37.7862 20.8406 37.8786 20.638H38.4849C38.3589 21.1419 37.9222 21.5001 37.3495 21.5001C36.6508 21.5001 36.1418 20.9795 36.1418 20.2966Z"
          fill="#1E1E1C"
        />
        <path
          d="M50.4386 20.1744V21.4582H49.9565V21.1335C49.8541 21.3394 49.5904 21.5017 49.2242 21.5017C48.5809 21.5017 48.072 21.0013 48.072 20.3049C48.072 19.6086 48.5809 19.0948 49.2797 19.0948C49.8524 19.0948 50.2874 19.4396 50.4151 19.9267H49.8087C49.7164 19.7409 49.5266 19.6321 49.2797 19.6321C48.8934 19.6321 48.6431 19.9032 48.6431 20.3116C48.6431 20.6832 48.8497 20.9812 49.2662 20.9812C49.5232 20.9812 49.7416 20.869 49.8356 20.6464H49.1436V20.1761H50.4386V20.1744Z"
          fill="#1E1E1C"
        />
        <path
          d="M56.356 19.6724H55.318V20.0172H56.2636V20.5545H55.318V20.9194H56.356V21.4567H54.752V19.1351H56.356V19.6724Z"
          fill="#1E1E1C"
        />
        <path
          d="M62.0666 20.598L62.0229 20.1076V19.1351H62.5889V21.4567H61.9473L61.0722 19.9938L61.1193 20.4842V21.4567H60.5532V19.1351H61.1965L62.0682 20.598H62.0666Z"
          fill="#1E1E1C"
        />
        <path
          d="M66.9023 19.1351H67.4684V21.4567H66.9023V19.1351Z"
          fill="#1E1E1C"
        />
        <path
          d="M73.7601 19.1351L73.7567 20.5076C73.7567 21.1671 73.3368 21.4985 72.7775 21.4985C72.2182 21.4985 71.7983 21.1671 71.7983 20.5076L71.7949 19.1351H72.371V20.4758C72.371 20.8407 72.5339 20.9629 72.7775 20.9629C73.021 20.9629 73.184 20.8407 73.184 20.4758V19.1351H73.7601Z"
          fill="#1E1E1C"
        />
        <path
          d="M79.884 20.7535C79.884 21.3209 79.3583 21.5 78.9854 21.5C78.2733 21.5 78.0029 21.0849 77.9575 20.6899H78.5303C78.584 20.8824 78.6865 20.9711 78.9821 20.9711C79.2122 20.9711 79.3146 20.8974 79.3146 20.7752C79.3146 20.6397 79.1752 20.6296 78.7856 20.5459C78.406 20.4656 78.0163 20.3267 78.0163 19.8463C78.0163 19.3357 78.4715 19.0897 78.9149 19.0897C79.5733 19.0897 79.8236 19.4579 79.8639 19.8597H79.2911C79.2575 19.7241 79.182 19.617 78.9149 19.617C78.6478 19.617 78.5857 19.7191 78.5857 19.8195C78.5857 19.9283 78.6915 19.9417 78.9216 19.9919C79.5733 20.1308 79.884 20.2764 79.884 20.7518V20.7535Z"
          fill="#1E1E1C"
        />
        <path
          d="M43.1644 21.378C43.1778 21.4249 43.2215 21.4584 43.2719 21.4584C43.3222 21.4584 43.3642 21.4249 43.3793 21.378C43.5675 20.7269 43.7035 20.5913 44.3569 20.4038C44.4039 20.3905 44.4375 20.3469 44.4375 20.2967C44.4375 20.2465 44.4039 20.2047 44.3569 20.1896C43.7035 20.0021 43.5675 19.8665 43.3793 19.2154C43.3659 19.1685 43.3222 19.1351 43.2719 19.1351C43.2215 19.1351 43.1795 19.1685 43.1644 19.2154C42.9762 19.8665 42.8402 20.0021 42.1868 20.1896C42.1398 20.203 42.1062 20.2465 42.1062 20.2967C42.1062 20.3469 42.1398 20.3888 42.1868 20.4038C42.8402 20.5913 42.9762 20.7269 43.1644 21.378Z"
          fill="#00B2BB"
        />
        <path
          d="M52.2847 3.50119H49.2732V16.3898H52.2847V3.50119Z"
          fill="#1E1E1C"
        />
        <path
          d="M55.5835 0.5H52.2864V3.5012H55.5835C58.3061 3.5012 60.5232 5.69895 60.5434 8.4089V16.3898H63.5549V8.4089C63.5364 4.04519 59.9673 0.5 55.5835 0.5Z"
          fill="#1E1E1C"
        />
        <path
          d="M12.933 8.44572C12.933 11.1707 10.7075 13.3886 7.97313 13.3886C5.23872 13.3886 3.01323 11.1707 3.01323 8.44572C3.01323 5.72071 5.23872 3.50287 7.97313 3.50287V0.5C3.5759 0.5 0 4.06361 0 8.44572C0 12.8278 3.5759 16.3914 7.97313 16.3914C12.3704 16.3914 15.9463 12.8278 15.9463 8.44572H12.9347H12.933Z"
          fill="#1E1E1C"
        />
        <path
          d="M12.9314 5.44284H7.97314V8.44404H12.9314V5.44284Z"
          fill="#00B2BB"
        />
        <path
          d="M29.3581 8.44572C29.3581 5.72071 27.1326 3.50287 24.3982 3.50287C21.6638 3.50287 19.4383 5.72071 19.4383 8.44572C19.4383 11.1707 21.6638 13.3886 24.3982 13.3886V16.3898C20.0026 16.3898 16.425 12.8262 16.425 8.44405C16.4234 4.06361 20.0009 0.5 24.3965 0.5C28.792 0.5 32.3696 4.06361 32.3696 8.44572H29.4253H29.3564H29.3581Z"
          fill="#1E1E1C"
        />
        <path
          d="M29.3547 8.44571H24.3965V11.4469H29.3547V8.44571Z"
          fill="#1E1E1C"
        />
        <path
          d="M45.7812 8.44572C45.7812 5.72071 43.5557 3.50287 40.8213 3.50287C38.0869 3.50287 35.8614 5.72071 35.8614 8.44572C35.8614 11.1707 38.0869 13.3886 40.8213 13.3886V16.3898C36.4257 16.3898 32.8481 12.8262 32.8481 8.44405C32.8481 4.06193 36.424 0.5 40.8213 0.5C45.2168 0.5 48.7944 4.06361 48.7944 8.44572H45.85H45.7812Z"
          fill="#1E1E1C"
        />
        <path
          d="M45.7795 8.44571H40.8213V11.4469H45.7795V8.44571Z"
          fill="#1E1E1C"
        />
        <path
          d="M76.9884 8.44572C76.9884 11.1707 74.763 13.3886 72.0285 13.3886C69.2941 13.3886 67.0686 11.1707 67.0686 8.44572C67.0686 5.72071 69.2941 3.50287 72.0285 3.50287V0.5C67.6313 0.5 64.0554 4.06361 64.0554 8.44572C64.0554 12.8278 67.6313 16.3914 72.0285 16.3914C76.4258 16.3914 80.0017 12.8278 80.0017 8.44572H76.9901H76.9884Z"
          fill="#1E1E1C"
        />
        <path
          d="M76.9851 5.44284H72.0269V8.44404H76.9851V5.44284Z"
          fill="#1E1E1C"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_2618">
          <rect
            width="80"
            height="21"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
